import * as React from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Outlet, useNavigate } from "react-router-dom";
import { colors } from "../../theme/Colors";
import IconBdigitalWhite from "../../imgs/BDIGITALL_WHITE.svg";
import MenuIcon from "@mui/icons-material/Menu";
import DefaultProfilePic from "../../imgs/user.png";
import Api from "../../api/Api";
import { Urls } from "../../api/Urls";
import { useAuthContext } from "../../hooks/useAuthContext";
import ListItemIcon from "@mui/material/ListItemIcon";
import IconCommande from "../../imgs/commande.svg";
import IconApresVente from "../../imgs/apresVente.svg";
import IconEntreprise from "../../imgs/entreprise.svg";
import contacts from "../../imgs/Contacts.svg";
import page from "../../imgs/page.svg";
import settings from '../../imgs/settings.svg'
import IconCarte from "../../imgs/carte.svg";
import { NavLink } from "react-router-dom";
import { ExpandLess, ExpandMore, InfoOutlined, Notifications } from "@mui/icons-material";
import { Button, Collapse } from "@mui/material";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { app } from "../../firebase";
import Cookies from 'js-cookie';
import API from "../../api/Api_1_3";
import { toast } from "react-toastify";



const drawerWidth = 300;
const navigation = [
  { title: "Mon entreprise", path: "/admin/mon-entreprise" },
  { title: "Gestion des BCARDs", path: "/admin/gestion-des-cartes" },
  { title: "Gestion des commandes", path: "/admin/gestion-des-commandes" },
  { title: "Service après vente", path: "/admin/service-apres-vente" },
  { title: "BDisplays", path: "/admin/pages"},
  {
    title: "Contacts",
    children: [
      { title: "Bcards Contacts", path: "/admin/contacts" },
      { title: "BDisplay Contacts", path: "/admin/bdisplay/contacts" },
    ],
  },
  { title: "Paramètres", path: "/admin/settings"},
];

const icons = {};
icons["Mon entreprise"] = IconEntreprise;
icons["Gestion des BCARDs"] = IconCarte;
icons["Gestion des commandes"] = IconCommande;
icons["Service après vente"] = IconApresVente;
icons["BDisplay"] = page;
icons["BDisplays"] = page;
icons["Contacts"] = contacts;
icons["BDisplay Contacts"] = contacts
icons["Bcards Contacts"] = contacts;
icons["Paramètres"] = settings

function SharedLayoutAdmin(props) {
  const [openMenus, setOpenMenus] = React.useState({ Contacts: false, BDisplay: false });

  const handleClick = (title) => {
    setOpenMenus((prevOpenMenus) => ({
      ...prevOpenMenus,
      [title]: !prevOpenMenus[title],
    }));
  };

  const { user } = useAuthContext();
  const access_token = user.access_token;
  const messaging = getMessaging(app);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [notifications,setNotifications] = React.useState([])

  const fcm_token = Cookies.get('fcm')

  const updateFcmToken = () =>{
       getToken(messaging,
        {vapidKey:'BPqGYSMiP2gllU5lEQ3aLYchXlX0WibmfULRXKzp_dROWj9TnNLusm03bIJQj_AazVgAeqJTIRcxzxK6l3WYf7k'})
        .then(async(currentToken) => {
        if(!fcm_token){
            Cookies.set('fcm',currentToken)
        }
        await API.patch(
          '/user/update-fcm-token',
          {
            fcm_token: currentToken
          },
          {
            headers: {
              "Content-Type": "application/json",
              "Authorization": `Bearer ${access_token}`
            }
          }
        );
      }).catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
     // ...
    });
  }

  const getNotfis = async ()=>{
    const res = await API.get('/user/notifications',{
      headers:{
        "Content-Type": "application/json",
        "Authorization": `Bearer ${access_token}`
      }
    })
    const data = res.data?.data
    setNotifications(data)
  }

  const handleClose = () => {
    setAnchorEl(null);
  };

  const markAsRead = async () =>{
    const res = await API.get('/user/notifications/mark-as-read',{
      headers:{
        "Content-Type": "application/json",
        "Authorization": `Bearer ${access_token}`
      }
    })
    const message = await res.data.message
    
    await getNotfis()
    handleClose()
    toast.success(message)
    
  }

  const CustomNotification = ({ title, body }) => (
    <div>
      <h3 style={{'fontSize':'1.1rem',marginBottom:'.5rem',fontWeight:600}}>{title}</h3>
      <p style={{fontWeight:300}}>{body}</p>
    </div>
  );

  onMessage(messaging,(payload) => {
    toast.info(<CustomNotification title={payload.notification.title} body={payload.notification.body} />);
    getNotfis()
  });

  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const openMenu = Boolean(anchorEl);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleClickMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const imageProfile = React.useRef();
  //use efffect image traitement
  React.useEffect(() => {
    const img = new Image();
    img.onload = function () {
      if (this.width > this.height) {
        imageProfile.current.style.height = "3rem";
        imageProfile.current.style.maxWidth = "auto";
        imageProfile.current.style.display = "block";
      } else {
        imageProfile.current.style.height = "auto";
        imageProfile.current.style.width = "3rem";
        imageProfile.current.style.display = "block";
      }
    };
    img.src = user?.user?.url_profile_picture;
    updateFcmToken()
    getNotfis()
  }, [user?.user?.url_profile_picture]);

  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const navigate = useNavigate();

  const handleLogout = () => {
    setAnchorElUser(null);

    Api.post(Urls.LOGOUT, null, {
      headers: {
        "content-type": "application/json",
        authorization: `Bearer ${access_token}`,
      },
    })
      .then((response) => {
        if (response.status === 200) {
          localStorage.removeItem("user");
          navigate("/login", { replace: true });
          navigate(0, { replace: true });
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.removeItem("user");
          navigate("/login", { replace: true });
          navigate(0, { replace: true });
        }
      });
  };

  const drawer = (
    <Box>
      <Toolbar
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box>
          <Box sx={{ padding: "2.5rem 0rem" }}>
            <Box>
              <img src={IconBdigitalWhite} width={150} alt="moonz logo" />
            </Box>
          </Box>
        </Box>
      </Toolbar>
  
      <List>
  {navigation.map((l, index) => {
    if (l.title === "Contacts") {
      return (
        <React.Fragment key={index}>
          <ListItem button sx={{ mb: 1 }} onClick={() => handleClick(l.title)}>
            <ListItemIcon>
              <img
                style={{ width: "20px", marginLeft: "20px" }}
                alt="icon"
                src={icons[l.title]}
              />
            </ListItemIcon>
            <ListItemText primary={l.title} sx={{ color: "white" }} />
            {openMenus["Contacts"] ? (
              <ExpandLess sx={{ color: "white" }} />
            ) : (
              <ExpandMore sx={{ color: "white" }} />
            )}
          </ListItem>
          <Collapse
            in={openMenus["Contacts"]}
            timeout="auto"
            unmountOnExit
          >
            <List component="div" disablePadding>
              {l.children.map((child, childIndex) => (
                <ListItem key={childIndex} button sx={{ pl: 4 }}>
                  <NavLink
                    id="navlink"
                    className={({ isActive }) =>
                      isActive ? "active" : "inactive"
                    }
                    to={child.path}
                  >
                    <Box sx={{ display: "flex", flexDirection: "row" }}>
                      <ListItemIcon>
                        <img
                          style={{ width: "20px", marginLeft: "20px" }}
                          alt="icon"
                          src={icons[child.title]}
                        />
                      </ListItemIcon>
                      <ListItemText
                        sx={{ color: "white" }}
                        primary={child.title}
                      />
                    </Box>
                  </NavLink>
                </ListItem>
              ))}
            </List>
          </Collapse>
        </React.Fragment>
      );
    } else {
      return (
        <ListItem key={index} disablePadding>
          <NavLink
            id="navlink"
            className={({ isActive }) => (isActive ? "active" : "inactive")}
            to={l.path}
          >
            <Box sx={{ display: "flex", flexDirection: "row" }}>
              <ListItemIcon>
                <img
                  style={{ width: "20px", marginLeft: "20px" }}
                  alt="icon"
                  src={icons[l.title]}
                />
              </ListItemIcon>
              <ListItemText sx={{ color: "white" }} primary={l.title} />
            </Box>
          </NavLink>
        </ListItem>
      );
    }
  })}
</List>

    </Box>
  );
  
  

  const container =
    window !== undefined ? () => window().document.body : undefined;
  function capitalizeFirstLetter(string) {
    if (string) {
      return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    } else return "";
  }
  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
      >
        <Toolbar
          sx={{
            backgroundColor: `${colors.bd_DarkPurple}`,
            color: "white",
            display: "flex",
            justifyContent: {
              xs:'space-between',
              sm:'space-between',
              md:'end',
              lg:'end',
              xl:'end'
            },
          }}
        >
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon></MenuIcon>
          </IconButton>
          <Box>
          <Button
              sx={{'cursor':'pointer',color:'white'}} 
              id="basic-button"
              aria-controls={ openMenu ? 'basic-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={ openMenu ? 'true' : undefined}
              onClick={handleClickMenu}
            >
              <Notifications/>
            </Button>
          <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={openMenu}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
              sx={{'width':'90%'}}
            >
              <MenuItem>
                <Box display={'flex'} width={'100%'} alignItems={'center'} justifyContent={'space-between'} >
                  <Typography variant="h6" color="initial">Notifications</Typography>
                  <Button disabled={notifications[0]?.read_at !== null} onClick={()=> markAsRead()} size='small'>Mark all as read</Button>
                </Box>
              </MenuItem>
              {notifications && notifications.map((ntf,index)=>(
                <MenuItem sx={{width:'100%','display':'flex',flexDirection:'row',padding:'1rem',alignItems:'center','opacity':ntf.read_at !== null ? 0.5 : 1,borderBottom:'.5px solid #232323',gap:'.5rem'}} key={index}>
                <Box>
                  <InfoOutlined/>
                </Box>
                <Box maxWidth={'sm'} display={'flex'} flexDirection={'column'} alignItems={'start'}>
                  <Typography variant="p" sx={{'fontSize':'1rem', fontWeight:ntf.read_at !== null ? 600 : 700}} color="initial">{ntf.notification_data.title}</Typography>
                  <Typography variant="body1" color="initial" sx={{ whiteSpace: 'break-spaces', fontSize:'.9rem' }}>{ntf.notification_data.body}</Typography> 
                </Box> 

              </MenuItem>  
              ))}

            </Menu>
          </Box>
          <Box display="flex" gap="1.5rem">
            <Box display="flex" alignItems="center" gap="0.5rem">
              <Typography noWrap component="div">
                {capitalizeFirstLetter(user?.user?.first_name?.split(" ")[0]) +
                  capitalizeFirstLetter(user?.user?.first_name?.split(" ")[1])}
              </Typography>
              <Typography noWrap component="div">
                {user?.user?.last_name?.toUpperCase()}
              </Typography>
              <Box>
                <Typography>- Administrateur</Typography>
              </Box>
            </Box>

            <Box>
              <IconButton
                onClick={handleOpenUserMenu}
                sx={{ width: "3rem", height: "3rem", p: 0 }}
              >
                <Avatar
                  sx={{ width: "3rem", height: "3rem", borderRadius: "50%" }}
                >
                  {user?.user?.url_profile_picture === null ? (
                    <img
                      style={{ width: "3rem" }}
                      src={DefaultProfilePic}
                      alt=""
                    />
                  ) : (
                    <img
                      ref={imageProfile}
                      style={{
                        objectFit: "fill",
                        objectPosition: "50% 50%",
                        display: "none",
                      }}
                      id="imgNavbar"
                      src={user?.user?.url_profile_picture}
                      alt=""
                    />
                  )}
                </Avatar>
              </IconButton>
              <Menu
                sx={{ mt: "45px" }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                <MenuItem>
                  <Typography
                    onClick={() => navigate("/admin/mon-compte")}
                    textAlign="center"
                  >
                    Mon compte
                  </Typography>
                </MenuItem>
                <MenuItem>
                  <Typography onClick={handleLogout} textAlign="center">
                    Se déconnecter
                  </Typography>
                </MenuItem>
              </Menu>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        <Toolbar />

        <Box sx={{ padding: { md: "2rem", sm: "0.2rem" } }}>
          <Outlet />
        </Box>
      </Box>
    </Box>
  );
}

SharedLayoutAdmin.propTypes = {
  window: PropTypes.func,
};

export default SharedLayoutAdmin;
