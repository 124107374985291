import React from "react";
import { Box, Typography } from "@mui/material";
import IconFile from "../../imgs/file.svg";

function IconLinkPublicProfile({ icon, title, value, hide }) {
  const fullUrl = value.startsWith('http://') || value.startsWith('https://')
    ? value
    : `https://${value}`;

  return (
    <Box sx={{ display: `${hide ? "none" : "flex"}` }} flexDirection="row" gap="0.5rem" alignItems="center">
      <Box display="flex" sx={{ alignItems: "center", justifyContent: "center", padding: "5px", margin: "0.5rem 0rem" }}>
        {icon ? <img width="22px" height="20px" alt={`${title} icon`} src={`${icon}`} /> : <img width="20px" height="20px" alt={`${title} icon`} src={IconFile} />}
      </Box>

      <Box>
        <Typography whiteSpace="pre-line" sx={{ wordBreak: "break-word" }}>
          <a target="_blank" rel="noreferrer" style={{ textDecoration: "none", color: "black", fontWeight:300 }} href={fullUrl}>
            {title}
          </a>
        </Typography>
      </Box>
    </Box>
  );
}

export default IconLinkPublicProfile;
