import React, { useState } from "react";
import { Button, Typography, Modal, Container, List, ListItem, useMediaQuery, Collapse, TextField, Box, Tabs, Tab, CircularProgress, FormControl, Switch, Checkbox } from "@mui/material";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { styled } from '@mui/material/styles';
import MzErrorText from "../../components/ui/MzErrorText";
import { ExpandLess, ExpandMore, Add } from "@mui/icons-material";
import { ContactStyle } from './ContactModalStyle';
import '../../pages/abonnee/style/SharedLayout.css';
import Api from "../../api/Api";
import { colors } from '../../theme/Colors';
import API from "../../api/Api_1_3";
import CloseIcon from '@mui/icons-material/Close';
import { ToastContainer, toast } from "react-toastify";

function ContactModal({ title, message, refuseLabel, onSuccess, onError, children, service_id, clickedButton, ...props }) {
  const [contactData, setContactData] = useState({
    service_id: service_id,
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    company: "",
    function: ""
  });

  const [contactDataEmail, setContactDataEmail] = useState({
    service_id: service_id,
    email: "",
  });
  const [policy, setPolicy] = useState(false);
  const [sending, setSending] = useState(false)
  const [isError, setIsError] = useState(false);
  const [ErrorMsg, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [isCollapsed, setIsCollapsed] = useState(true);

  const checkEmptyProperties = (obj) => {
    const requiredFields = ["first_name", "last_name", "phone", 'email'];
    for (const key in obj) {
      if (requiredFields.includes(key) && (obj[key] === '' || obj[key] === null)) {
        setIsError(true);
        return true;
      }
    }
    setIsError(false);
    return false;
  };  

  const handleSendStats = async (id) => {
    const data = {
      service_id: id,
      type_action: clickedButton === 'CONSULT_URL' ? 'CONSULT_URL' : 'DOWNLOAD_BROCHURE'
    };
    try {
      const response = await Api.post(`/bdisplay/services/store-consultation`, data, {
        headers: {
          "Content-Type": "application/json",
          "Accept": "application/json",
        },
      });
      if (response.status === 200) {
        console.log('success !');
      }
    } catch (error) {
      console.log(error);
    }
  };

  async function handleSubmit(values, { resetForm }) {
    setSending(true);
    setIsError(false);
    setError('');
  
    try {
      let data;
      if (value === false) {
        data = {
          service_id,
          first_name: values.first_name,
          last_name: values.last_name,
          phone: values.phone,
          email: values.email || undefined,
          company: values.company_name || undefined,
          function: values.function || undefined,
        };
      } else {
        data = {
          service_id,
          email: values.email,
        };
      }
  
      const endpoint = value ? "/bdisplay-contacts/store-existing-user-as-contact" : "/bdisplay-contacts";
      const api = value ? API : Api;
  
      const response = await api.post(endpoint, data, {
        headers: {
          "Content-Type": "application/json"
        },
      });
  
      if (response.status === 201) {
        toast.success("Vous avez ajouté votre contact !");
        await handleSendStats(service_id);
        onSuccess();
        setTimeout(() => {
          props.onClose();
        }, 1000);
        resetForm();
      }
    } catch (error) {
      const errorMessage = error.response?.data?.message || "Une erreur s'est produite. Veuillez réessayer.";
      setIsError(true);
      setError(errorMessage);
      toast.error(errorMessage);
    } finally {
      setSending(false);
    }
  }

  // const handleSubmit = async (values, { resetForm }) => {
  //   try {
  //     setIsLoading(true);
  //     if (checkEmptyProperties(contactData)) {
  //       setIsLoading(false);
  //       return;
  //     }
  //     const filteredContactData = { ...contactData };
  //     ['company', 'function'].forEach(field => {
  //       if (!filteredContactData[field]) {
  //         delete filteredContactData[field];
  //       }
  //     });
  //     handleSendStats(service_id);
  //     let response;
  //      if (value === false){
  //       response = await Api.post("/bdisplay-contacts", filteredContactData, {
  //         headers: {
  //           "Content-Type": "application/json"
  //         },
  //       });
  //      }
  //      else if (value === true){
  //       handleSubmitEmail()
  //      }
  //     if (response.status === 201) {
  //       setTimeout(() => {
  //         props.onClose();
  //       }, 1000);
  //       onSuccess();
  //       setIsLoading(false);
  //     } else {
  //       onError(response);
  //     }
  //   } catch (error) {
  //     onError(error.response.data.message);
  //     setIsLoading(false);
  //   }
  // };

  const handleSubmitEmail = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      if (checkEmptyProperties(contactDataEmail)) {
        setIsLoading(false);
        return;
      }
      const filteredContactData = { ...contactDataEmail };
      handleSendStats(service_id);
      const response = await API.post("/bdisplay-contacts/store-existing-user-as-contact", filteredContactData, {
        headers: {
          "Content-Type": "application/json"
        },
      });
      if (response.status === 201) {
        setTimeout(() => {
          props.onClose();
        }, 1000);
        onSuccess();
        setIsLoading(false);
      } else {
        onError(response);
      }
    } catch (error) {
      setIsError(true)
      setError(error.response.data.message)
      onError(error.response.data.message);
      setIsLoading(false);
    }
  };

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  const [value, setValue] = useState(false);
  const handleChangeTab = (event) => {
    setValue(event.target.checked);
  };
  const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: 2,
      transitionDuration: '300ms',
      '&.Mui-checked': {
        transform: 'translateX(16px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          backgroundColor: '#964CFC',
          opacity: 1,
          border: 0,
          ...theme.applyStyles('dark', {
            backgroundColor: '#2ECA45',
          }),
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.5,
        },
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: '#33cf4d',
        border: '6px solid #fff',
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        color: theme.palette.grey[100],
        ...theme.applyStyles('dark', {
          color: theme.palette.grey[600],
        }),
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.7,
        ...theme.applyStyles('dark', {
          opacity: 0.3,
        }),
      },
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: 22,
      height: 22,
    },
    '& .MuiSwitch-track': {
      borderRadius: 26 / 2,
      backgroundColor: '#E9E9EA',
      opacity: 1,
      transition: theme.transitions.create(['background-color'], {
        duration: 500,
      }),
      ...theme.applyStyles('dark', {
        backgroundColor: '#39393D',
      }),
    },
  }));

  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const modalStyle = {
    position: 'absolute',
    top: isSmallScreen ? '50%' : '50%',
    left: isSmallScreen ? '50%' : '50%',
    transform: 'translate(-50%, -50%)',
    width: isSmallScreen ? '90vw' : '400px', // Adjust the width for small screens
    maxWidth: '500px', // Add a maximum width for larger screens
    padding: '1.5rem',
    backgroundColor: '#FAFAFA',
    borderRadius: '5px',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
  };


  return (
      <Modal open={props.open} onClose={props.onClose}>
        <Container>
          <Formik
                    enableReinitialize={true}
                    initialValues={{
                      email: contactData.email,
                      function:contactData.function,
                      last_name: contactData.last_name,
                      first_name: contactData.first_name,
                      phone: contactData.phone,
                      company_name: contactData.company_name,
                      policy: policy,
                    }}
                    onSubmit={(values, actions) => {
                      handleSubmit(values, actions);
                    }}
                  >
                    {({ values, handleChange }) => (
                        <Form>
                        <Box
                          style={modalStyle}
                        >
                          {/* title + close icon */}
                          <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                            <Typography variant="h6" fontWeight={400}>
                              {title}
                            </Typography>
                            <Box onClick={props.onClose} sx={{cursor:'pointer'}}>
                              <CloseIcon/>
                            </Box>
                          </Box>

                          <Box mt={2} mb={3} >
                          <FormControl>
                            <Box display={'flex'} gap={1} alignItems={'center'}>
                              <IOSSwitch
                                checked={value}
                                onChange={handleChangeTab}
                                inputProps={{ 'aria-label': 'iOS style switch' }}
                              />
                              <Typography variant='subtitle1' sx={{fontSize:'.9rem'}} id="value">Déja Client BdigitAll</Typography>
                            </Box>
                            </FormControl>
                          <ErrorMessage name="value" component={MzErrorText} />
                          </Box>

                        { value === false && <>

                          {/* prenom */}
                          <Box
                            marginRight="1rem"
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              marginBottom: "1.3rem",
                              gap:1
                            }}
                          >
                            <label style={{fontSize:15}}>Prénom*</label>
                            <Field
                              name="first_name"
                              as={TextField}
                              variant="outlined"
                              placeholder="Prénom"
                              size="small"
                              InputProps={{
                                style: {
                                  backgroundColor: '#F5F5F5',
                                },
                              }}
                            />
                            <ErrorMessage
                              name="first_name"
                              component={MzErrorText}
                            />
                          </Box>

                          {/* nom */}
                          <Box
                            marginRight="1rem"
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              marginBottom: "1.3rem",
                              gap:1
                            }}
                          >
                            <label style={{fontSize:15}}>Nom*</label>
                            <Field
                              name="last_name"
                              as={TextField}
                              variant="outlined"
                              placeholder="Nom"
                              size="small"
                              InputProps={{
                                style: {
                                  backgroundColor: '#F5F5F5',
                                },
                              }}
                            />
                            <ErrorMessage
                              name="last_name"
                              component={MzErrorText}
                            />
                          </Box>

                          {/* phone */}
                          <Box
                            marginRight="1rem"
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              gap:1
                            }}
                          >
                            <label style={{fontSize:15}}>Numéro de téléphone*</label>
                            <Field
                              name="phone"
                              as={TextField}
                              variant="outlined"
                              placeholder="+213 000 00 00 00"
                              size="small"
                              InputProps={{
                                style: {
                                  backgroundColor: '#F5F5F5',
                                },
                              }}
                            />
                            <ErrorMessage
                              name="phone"
                              component={MzErrorText}
                            />
                          </Box>

                          {/* more */}
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <List>
                              <ListItem onClick={toggleCollapse} sx={{cursor:'pointer'}}>
                                <Typography variant="p" fontSize={'.8rem'} color={colors.gray}>
                                  Afficher plus de détails
                                </Typography>
                                {isCollapsed ? <Add sx={{'color':colors.gray}} /> : <ExpandLess sx={{'color':colors.gray}} />}
                              </ListItem>
                              <Collapse sx={{width:'100%', margin:0, padding:0}} in={!isCollapsed} timeout="auto" unmountOnExit>
                                <ListItem sx={{display:'flex', flexDirection:'column',alignItems:'start', justifyContent:'center', gap:1}}>
                                <label style={{fontSize:15}}>Email (optionnel)</label>
                                <Field
                                  name="email"
                                  as={TextField}
                                  variant="outlined"
                                  placeholder="example@email.com"
                                  size="small"
                                  fullWidth
                                  InputProps={{
                                    style: {
                                      backgroundColor: '#F5F5F5',
                                    },
                                  }}
                                />
                                </ListItem>
                                <ListItem sx={{display:'flex', flexDirection:'column',alignItems:'start', justifyContent:'start', gap:1}}>
                                <label style={{fontSize:15}}>Nom de l'entreprise (optionnel)</label>
                                <Field
                                  name="company_name"
                                  as={TextField}
                                  variant="outlined"
                                  placeholder="Mon Entreprise"
                                  size="small"
                                  fullWidth
                                  InputProps={{
                                    style: {
                                      backgroundColor: '#F5F5F5',
                                    },
                                  }}
                                />
                                </ListItem>
                                <ListItem sx={{display:'flex', flexDirection:'column',alignItems:'start', justifyContent:'start', gap:1}}>
                                <label style={{fontSize:15}}>Fonction (optionnel)</label>
                                <Field
                                  name="function"
                                  as={TextField}
                                  variant="outlined"
                                  placeholder="ex: CEO"
                                  size="small"
                                  fullWidth
                                  InputProps={{
                                    style: {
                                      backgroundColor: '#F5F5F5',
                                    },
                                  }}
                                />
                                </ListItem>
                              </Collapse>
                            </List>
                          </Box>

                          {/* policy */}
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "start",
                              marginBottom: "1.3rem",
                            }}
                          >
                            <Checkbox
                              name="policy"
                              required
                              variant="outlined"
                              label="Condition et Politique"
                              size="large"
                              sx={{
                                '&.Mui-checked': {
                                  color: '#964CFC',
                                },
                                '&.Mui-checked .MuiSvgIcon-root': {
                                  fill: '#964CFC',
                                },
                              }}
                            />
                            En soumettant, j'accepte la politique de confidentialité et le traitement de mes coordonnées. Vous pouvez révoquer ce consentement à tout moment.
                            <ErrorMessage
                              name="policy"
                              component={MzErrorText}
                            />
                          </Box>

                          {isError && <p style={{'color':`${colors.mjRedOutline}`}} className=" col-start-[span_1] md:col-start-[span_2] lg:col-start-[span_2]">Veuillez remplir les champs obligatoire </p>}
                          </>
                        }

                        { value === true && <>
                          {/* email */}
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              marginBottom: "1.3rem",
                              gap:1
                            }}
                          >
                            <label style={{fontSize:15}}>Email*</label>
                            <Field
                              name="email"
                              as={TextField}
                              variant="outlined"
                              placeholder="example@email.com"
                              size="small"
                              fullWidth
                              InputProps={{
                                style: {
                                  backgroundColor: '#F5F5F5',
                                },
                              }}
                            />
                            {isError && <p style={{'color':`${colors.mjRedOutline}`}} className=" col-start-[span_1] md:col-start-[span_2] lg:col-start-[span_2]">{ErrorMsg}</p>}
                          </Box>
                          
                          {/* policy */}
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "start",
                              justifyContent:'start',
                              marginBottom: "1.3rem",
                            }}
                          >
                            <Checkbox
                              name="policy"
                              required
                              variant="outlined"
                              label="Condition et Politique"
                              size="large"
                              sx={{
                                '&.Mui-checked': {
                                  color: '#964CFC',
                                },
                                '&.Mui-checked .MuiSvgIcon-root': {
                                  fill: '#964CFC',
                                },
                              }}
                            />
                              En soumettant, j'accepte la politique de confidentialité et le traitement de mes coordonnées. Vous pouvez révoquer ce consentement à tout moment.
                            <ErrorMessage
                              name="policy"
                              component={MzErrorText}
                            />
                          </Box>
                          </>
                        }

                          <Box
                            sx={{
                              display: "flex",
                              gap: "0.5rem",
                              flexDirection: "column",
                            }}
                          >
                            <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
                                { sending ?
                                <CircularProgress/>
                                :
                                <Button
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  width: {
                                    xs: "100%",
                                    md: "100%",
                                    sm: "100%",
                                    lg: "100%",
                                  },
                                  backgroundColor:'#964CFC',
                                  border: 'none',
                                  borderRadius: '16px',
                                  padding: '10px 0px',
                                  fontWeight:500
                                }}
                                disabled={sending}
                                variant="primary"
                                type="submit"
                              >
                                Envoyer
                              </Button> 
                                }
                            </Box>
                          </Box>
                        </Box>
                        </Form>
                    )}
          </Formik>
        </Container>
      </Modal>
  );
}

export default ContactModal;
