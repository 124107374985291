import { Box, Tab, Tabs, Typography } from "@mui/material";
import PagesGroupes from './PagesGroupes.jsx'
import CommentsTypes from './CommentsTypes.jsx'
import { useState } from "react";
function Settings() {
  const [value, setValue] = useState('one');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Box>
      {/* Header Section */}
      <Box className="header" sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
        <Typography variant="h4" component="h1" align="start" style={{ wordWrap: "break-word", fontWeight: 400, marginTop: "50px" }}>
          Settings
        </Typography>
      </Box>
      <Box mt={3}>
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="tabs"
      >
        <Tab
          value="one"
          label="Groupes"
        />
        <Tab value="two" label="Types Des Commentaires" />
      </Tabs>
      </Box>

      <Box>
        {value === 'one' && <PagesGroupes/>}
        {value === 'two' && <CommentsTypes/>}
      </Box>
    </Box>
  );
}

export default Settings;
