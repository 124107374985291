import { Container, TextField, Checkbox, useMediaQuery, ListItem, List, Collapse, CircularProgress, Tabs, Tab, FormControl, Switch } from "@mui/material";
import React from "react";
import qrIconin from "../../imgs/qrcodeinvalide.png";
import MzErrorText from "../../components/ui/MzErrorText";
import { useState, useEffect, useRef } from "react";
import { Box, Button, Typography, Avatar, Modal } from "@mui/material";
import { colors } from "../../theme/Colors";
import Api from "../../api/Api";
import API from "../../api/Api_1_3";
import { Urls } from "../../api/Urls";
import bdigitall from "../../imgs/BDIGITALL.svg";
import { useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Globe from '../../imgs/globe.svg'
import Document from '../../imgs/document.svg'
import Links from "../../components/ui/IconLinkPublicProfile";
import plus from "../../imgs/plus.svg";
import QrCode from "../../imgs/QrCode.svg";
import ShareContact from '../../imgs/Share 2.svg'
import { ModalStyleQRCODE } from "../../theme/ModalStyleQRCODE";
import IconLinkRow from "../../components/ui/IconLinkRow";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { Add, ExpandLess } from "@mui/icons-material";
import { styled } from '@mui/material/styles';
import Send from '../../imgs/prime_send.svg'
import ContactList from '../../imgs/contactlist.svg'
import CloseIcon from '@mui/icons-material/Close';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { useTheme } from "@mui/material/styles";

function AbonneeApercue() {
  //const { user } = useAuthContext();
  const location = useLocation();
  const id = location.pathname.split("/")[2];

  const [image, setImage] = useState();
  const [data, setData] = useState();
  const [links, setLinks] = useState();
  const [docs, setDocs] = useState();

  const [policy, setPolicy] = useState(false);

  //loading state
  const [loading, setLoading] = useState(true);
  const [sending, setSending] = useState(false)
  //modal config
  const [error, setError] = useState(false);
  const [errornotfound, setErroNotFound] = useState(false);
  const imageProfile = useRef();
  

  //on page load (load user details)
  useEffect(() => {
    Api.get(Urls.GET_USER_ACCOUNT_DETAILS + `/${id}`, {
      headers: {
        "Content-type": "application/json",
        accept: "application/json",
      },
    })
      .then((response) => {
        setData(response.data.user);
        setLinks(response.data.user?.user_links);
        //console.log(response.data.user);
        setDocs(response.data.user.documents);
        setLoading(false);
      })
      .catch((error) => {
        if (error.response.status === 405) {
          setError(true);
          setLoading(false);
        }
        if (error.response.status === 404) {
          setErroNotFound(true);
          setLoading(false);
        }
        if (error.response.status === 403)
          toast.error("Une erreur est survenue");
      });
  }, []);

  //open modal
  const [open, setOpen] = useState(false);
  const [openForm, setOpenForm] = useState(false);
  //share
  const share = () => {
    var browser = (function (agent) {
      switch (true) {
        case agent.indexOf("edge") > -1:
          return "edge";
        case agent.indexOf("edg") > -1:
          return "chromium based edge (dev or canary)";
        case agent.indexOf("opr") > -1 && !!window.opr:
          return "opera";
        case agent.indexOf("chrome") > -1 && !!window.chrome:
          return "chrome";
        case agent.indexOf("trident") > -1:
          return "ie";
        case agent.indexOf("firefox") > -1:
          return "firefox";
        case agent.indexOf("safari") > -1:
          return "safari";
        default:
          return "other";
      }
    })(window.navigator.userAgent.toLowerCase());
    //console.log(browser);
    const url = data.url_qrcode;
    const shareData = {
      title: "Partage de code QR",
      url: `${window.location.href}`,
      text: "Venez consulter mon profil sur BdigitAll !",
    };
    if (
      navigator.share &&
      navigator.canShare(shareData) &&
      browser !== "chromium based edge (dev or canary)" &&
      window.location.href.split("/")[0] === "https:"
    ) {
      navigator.share(shareData);
    } else {
      navigator["clipboard"].writeText(`${shareData.url}`);
      toast.success("Le lien a été copié !");
    }
  };

  const showForm = () =>{
    setOpenForm(!openForm)
  }

  function capitalizeFirstLetter(string) {
    if (string) {
      return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    } else return "";
  }
  // funciton to get the image on base64
  function toDataURL(url, callback) {
    var xhr = new XMLHttpRequest();
    xhr.onload = function () {
      var reader = new FileReader();
      reader.onloadend = function () {
        callback(reader.result);
      };
      reader.readAsDataURL(xhr.response);
    };
    xhr.open("GET", url);
    xhr.responseType = "blob";
    xhr.send();
  }
  const getvcf = () => {
    window.open(data?.url_vcf_file, "download");
  };

  const [form, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    function:"",
    phone: "",
    company_name: "",
  });

  const [progress, setProgress] = useState(0);

  const checkEmptyProperties = (obj) => {
    for (const key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        if (obj[key] === '' || obj[key] === null) {
          setIsError(true)
          return ; // Stop checking and return true if any property is empty string or null
        }
      }
    }
    setIsError(false)
    return false; // Return false if no property is empty string or null
  };

  async function onSubmitContact(values, { resetForm }) {
    const url = window.location;
    const data = {};
    const validatedData = {
      phone: values.phone,
      last_name: values.last_name,
      first_name: values.first_name,
    };
  
    checkEmptyProperties(validatedData);
  
    if (values.email === "" || values.email === null) {
      delete values.email;
    }
  
    if (values.company_name === "" || values.company_name === null) {
      delete values.company_name;
    }
  
    if (values.function === "" || values.function === null) {
      delete values.function;
    }
  
    setSending(true);
  
    let response ;
    try {
      if(value === false){
      response =  await Api.post((Urls.ADD_CONTACTS), {
          id: url.pathname.split("/")[2],
          ...values,
        });
      }
      else if(value === true) {
       response = await API.post(('/contacts/store-existing-user-as-contact'), {
          id: url.pathname.split("/")[2],
          email:values.email,
        });
      }
        
  
      if (response.status === 200) {
        toast.success("vous avez ajouté votre contact!");
        setProgress(0);
        resetForm();
      }
  
      setOpenForm(false);
    } catch (error) {
      if(value === true){
        setError(error.response.data.message)
      }
      toast.error(error.response.data.message);
      setSending(false);
    } finally {
      setSending(false);
    }
  }
  
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const [isError,setIsError] = useState(false)
  const [ErrorMsg,setErrorMsg] = useState('')

  const modalStyle = {
    position: 'absolute',
    top: isSmallScreen ? '50%' : '50%',
    left: isSmallScreen ? '50%' : '50%',
    transform: 'translate(-50%, -50%)',
    width: isSmallScreen ? '90vw' : '400px', // Adjust the width for small screens
    maxWidth: '500px', // Add a maximum width for larger screens
    padding: '1.5rem',
    backgroundColor: '#FAFAFA',
    borderRadius: '5px',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
  };

  const [isCollapsed, setIsCollapsed] = useState(true);

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  const [value, setValue] = useState(false);

  const handleChangeTab = (event) => {
    setValue(event.target.checked);
  };

  const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: 2,
      transitionDuration: '300ms',
      '&.Mui-checked': {
        transform: 'translateX(16px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          backgroundColor: '#964CFC',
          opacity: 1,
          border: 0,
          ...theme.applyStyles('dark', {
            backgroundColor: '#2ECA45',
          }),
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.5,
        },
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: '#33cf4d',
        border: '6px solid #fff',
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        color: theme.palette.grey[100],
        ...theme.applyStyles('dark', {
          color: theme.palette.grey[600],
        }),
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.7,
        ...theme.applyStyles('dark', {
          opacity: 0.3,
        }),
      },
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: 22,
      height: 22,
    },
    '& .MuiSwitch-track': {
      borderRadius: 26 / 2,
      backgroundColor: '#E9E9EA',
      opacity: 1,
      transition: theme.transitions.create(['background-color'], {
        duration: 500,
      }),
      ...theme.applyStyles('dark', {
        backgroundColor: '#39393D',
      }),
    },
  }));

  const theme = useTheme();
  const isXsScreen = useMediaQuery(theme.breakpoints.only('xs'));
  const isSmScreen = useMediaQuery(theme.breakpoints.only('sm'));
  const isMdScreen = useMediaQuery(theme.breakpoints.only('md'));
  const isLgScreen = useMediaQuery(theme.breakpoints.up('lg'));


  const getImageStyle = () => {
    let width, height;

    if (isXsScreen || isSmScreen) {
      width = 110;
      height = 110;
    } else if (isMdScreen) {
      width = 120;
      height = 120;
    } else if (isLgScreen) {
      width = 135;
      height = 135;
    }

    return {
      width,
      height,
      objectFit: "cover",
    };
  };

  const getAvatarStyle = () => {
    let width, height;

    if (isXsScreen || isSmScreen) {
      width = 100;
      height = 100;
    } else if (isMdScreen) {
      width = 110;
      height = 110;
    } else if (isLgScreen) {
      width = 115;
      height = 115;
    }

    return {
      width,
      height,
      objectFit: "cover",
    };
  };
  const getProfilePictureStyle = () => {
    let width, height;

    if (isXsScreen || isSmScreen) {
      width = '140px';
      height = '140px';
    } else if (isMdScreen) {
      width = '200px';
      height = '200px';
    } else if (isLgScreen) {
      width = '200px';
      height = '200px';
    }

    return {
      width,
      height,
      objectFit: "contain",
      objectPosition: "50% 50%",
    };
  };

  return (
    <Box>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      {loading === false ? (
        <>
          {error === false && errornotfound === false ? (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                alignSelf: 'center',
                justifyContent: 'center',
              }}
            >
              <Container
                sx={{
                  minHeight: "100%",
                  display: "flex",
                  alignItems: "center",
                  alignSelf: 'center',
                  justifyContent: 'center',
                  padding:{
                    xs: 0,
                    md: 0,
                    sm: 0,
                    lg: 0,
                  },
                  margin:{
                    xs: 0,
                    md: 0,
                    sm: 0,
                    lg: 0,
                  },
                }}
                maxWidth="md"
              >
                <Box
                  gap="1rem"
                  width="725px"
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    position:'relative',
                  }}
                >
                  {/* Upper Actions */}
                 <Box
                  display={'flex'}
                  gap={'.5rem'}
                  alignItems={'center'}
                  justifyContent={'end'}
                  sx={{
                    position: 'absolute',
                    top:10,
                    zIndex:100,
                    left:{
                      xs: '72%',
                      sm: '73%',
                      md: '85%',
                      lg: '85%',
                    }
                  }}
                 >
                 <img
                    onClick={share}
                    src={ShareContact}
                    alt="share_contact_icon"
                    width={40}
                    style={{
                      cursor: "pointer",
                      backgroundColor:'white',
                      color: colors.bd_Purple,
                      borderRadius:'20px',
                      padding:'7px',
                      boxShadow:'0px 4px 4px 0px rgba(0,0,0,0.25)'
                    }}
                  />

                  <img
                    onClick={() => setOpen(true)}
                    src={QrCode}
                    alt="qr_code"
                    width={40}
                    style={{
                      cursor: "pointer",
                      backgroundColor:'white',
                      color: colors.bd_Purple,
                      borderRadius:'20px',
                      padding:'5px',
                      boxShadow:'0px 4px 4px 0px rgba(0,0,0,0.25)'
                    }}
                  />
                 </Box>


                  <Modal open={open} onClose={() => setOpen(false)}>
                    <Box
                      display="flex"
                      flexDirection="column"
                      justifyContent="center"
                      alignItems="center"
                      sx={ModalStyleQRCODE}
                    >
                      <Avatar
                        sx={{
                          position: "relative",
                          width: "17rem",
                          height: "17rem",

                          marginBottom: "0rem",
                        }}
                        variant="rounded"
                      >
                        {data?.url_qrcode ? (
                          <img
                            alt="url_logo"
                            src={data?.url_qrcode}
                            style={{
                              width: "17rem",
                              objectFit: "fill",
                              objectPosition: "50% 50%",
                            }}
                          />
                        ) : (
                          <img
                            src={null}
                            alt="placeholder"
                            style={{
                              width: "17rem",
                              objectFit: "fill",
                              objectPosition: "50% 50%",
                            }}
                          />
                        )}
                      </Avatar>
                    </Box>
                  </Modal>
                  
                  {/* Contact Assets */}
                  <Box
                    display={'flex'}
                    flexDirection={'column'}
                    alignItems={'center'}
                    justifyContent={'center'}
                  >

                      {/* Company logo */}
                      {
                        (typeof data?.url_cover_picture === 'string') ?
                        // Custom Cover
                        <Box
                          display="flex"
                          width={'100%'}
                          sx={{
                            position: 'relative',
                            height: '224px', // Adjust height to auto
                            justifyContent: 'space-between',
                          }}
                        >
                        <img
                          alt="company_cover"
                          width={'100%'}
                          src={data?.url_cover_picture}
                          style={{ objectFit: 'cover', height: '224px' }} // Ensure correct object fit and height
                        />
                        </Box>
                        :   
                        // Default Cover
                        <Box
                      display="flex"
                      width={'100%'}
                      sx={{
                        backgroundColor: colors.main_purple_cover,
                        position: 'relative',
                        height: '224px',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        padding: '1rem 1rem 0rem 1rem',
                      }}
                    >
                      {data?.collaborated_enterprise?.url_logo && (
                      <>
                        <Box
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                        alignItems="flex-start"
                        color="white"
                      >
                        {/* Cover Data */}
                        <Box display={'flex'} mt={{
                          xs:-5,
                          sm:-5,
                          md:0,
                          lg:0,
                          xl:0
                        }} 
                        flexDirection={'column'} gap={3} justifyContent={'flex-end'}>
                          <Typography variant="h4" fontWeight={400} color={colors.textYellow}>
                            { data?.collaborated_enterprise?.social_reason}
                          </Typography>
                            {/* location */}
                          <Box display={'flex'} gap={1}>
                              <LocationOnIcon sx={{color:'black', paddingX:.3, paddingY:.3, borderRadius:'10px', backgroundColor:colors.textYellow}}/>
                              <Typography 
                                variant="subtitle1" 
                                fontWeight={400} 
                                color={colors.textWhite} 
                                sx={{
                                  overflowWrap:'break-word',
                                  fontSize: {
                                    xs: '0.75rem',
                                    sm: '0.75rem',
                                    md: '1.1rem',
                                  },
                                  textAlign: {
                                    xs: 'center',
                                    sm: 'center',
                                    md: 'left',
                                  }
                                }}
                              >
                                { data?.collaborated_enterprise?.address + 
                                  ', ' + 
                                  data?.collaborated_enterprise?.city_name +
                                   ', ' +
                                   data?.collaborated_enterprise?.country_name
                                }
                              </Typography>
                          </Box>
                        </Box>
                        </Box>
                        {/* Company Photo */}
                        <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Avatar
                          alt="company_logo"
                          sx={[getAvatarStyle(),{
                            position: "relative",
                            backgroundColor: 'white',
                            borderRadius: '50%',
                            objectPosition: "50% 50%",
                          }]}
                          variant="rounded"
                        >
                          <img
                            alt="company_cover"
                            src={data?.collaborated_enterprise?.url_logo}
                            style={getImageStyle()}
                          />
                        </Avatar>
                        </Box>
                      </>
                    )}
                        </Box>
                      }

                  {/* Contact profile picture */}
                  <Box
                    display="flex"
                    justifyContent={'center'}
                    sx={{
                      position: 'relative',
                      top:{
                        xs:-80,
                        sm:-80,
                        md:-105,
                        lg:-105,
                        xl:-105
                      }
                    }}
                  > 
                    {data?.url_profile_picture && (
                      <Avatar
                        sx={{
                          position: "relative",
                          borderRadius: "50%",
                          width: {
                            xs:'140px',
                            sm:'140px',
                            md:'200px',
                            lg:'200px',
                            xl:'200px'
                          },
                          height: {
                            xs:'140px',
                            sm:'140px',
                            md:'200px',
                            lg:'200px',
                            xl:'200px'
                          },
                          marginBottom: "1rem",
                          backgroundColor: "white",
                          border: '2px solid white'
                        }}
                        variant="rounded"
                      >
                        {image ? (
                          <img
                            alt="logo"
                            src={image}
                            style={getProfilePictureStyle()}
                          />
                        ) : (
                          <img
                            ref={imageProfile}
                            alt="logo"
                            src={data?.url_profile_picture}
                            style={getProfilePictureStyle()}
                          />
                        )}
                      </Avatar>
                    )}
                  </Box>

                  </Box>
                  
                  <Box mt={{
                    xs:-12,
                    sm:-12,
                    md:-16,
                    lg:-16,
                    xl:-16
                  }} display={'flex'} flexDirection={'column'}>

                  {/* Contact data */}
                  <Box
                    width="100%"
                    display="flex"
                    flexDirection="column"
                    justifyContent={'center'}
                    alignItems={'center'}
                    gap={{
                      xs:"3px",
                      sm:"3px",
                      md:"8px",
                      lg:"8px",
                      xl:"8px"
                    }}
                  >
                    <Typography
                      sx={{
                        textAlign: {
                          xs: "center",
                          sm: "center",
                          md: "center",
                          lg: "start",
                        },
                      }}
                      variant="h5"
                      fontWeight={500}
                      fontSize={{
                        xs: '18px',
                        sm: '18px',
                        md: '24px',
                        lg: '24px',
                      }}
                    >
                      {`${
                        data?.first_name
                          ? capitalizeFirstLetter(
                              data?.first_name?.split(" ")[0]
                            )
                          : null
                      } ${
                        data?.first_name
                          ? capitalizeFirstLetter(
                              data?.first_name?.split(" ")[1]
                            )
                          : null
                      } ${
                        data?.last_name ? data?.last_name?.toUpperCase() : null
                      }`}{" "}
                    </Typography>
                    <Typography
                      sx={{
                        textAlign: {
                          xs: "center",
                          md: "center",
                          sm: "center",
                          lg: "start",
                        },
                      }}
                      variant="h6"
                      fontWeight={300}
                      fontSize={{
                        xs: '16px',
                        sm: '16px',
                        md: '20px',
                        lg: '20px',
                      }}
                    >
                      {data?.function ? data?.function : null}
                    </Typography>
                  </Box>

                  {links && (
                    <Box
                      gap="0.5rem"
                      width="100%"
                      display="flex"
                      mt={{
                        xs:'10px',
                        sm:'10px',
                        md:'16px',
                        lg:'16px',
                        xl:'16px'
                      }}
                      sx={{
                        alignItems: "center",
                        justifyContent: {
                          xs: "center",
                          md: "center",
                          sm: "center",
                          lg: "center",
                        },
                      }}
                    >
                      {links.map((l) => (
                        <IconLinkRow
                          key={l.id}
                          hide={l.link.title === "Site Web" ? true : false}
                          value={l.value}
                          icon={l.link.url_logo}
                          slug={l.link.slug}
                        />
                      ))}
                    </Box>
                  )}
                  
                  {/* Contact CTA */}
                  <Box
                    gap="1rem"
                    width="100%"
                    display="flex"
                    my={{
                      xs:'24px',
                      sm:'24px',
                      md:'30px',
                      lg:'30px',
                      xl:'30px'
                    }}
                    sx={{
                      alignItems: "center",
                      flexDirection: {
                        sm: "column-reverse",
                        md: "column-reverse",
                        lg: "row",
                        xs: "column-reverse",
                      },
                      justifyContent: {
                        lg: "space-between",
                        sm: "center",
                        md: "center",
                        xs: "center",
                      },
                      padding: '0px 16px'
                    }}
                  >
                    <Box
                      display="flex"
                      sx={{
                        marginTop: {
                          xs: ".5rem",
                          sm: ".5rem",
                          md: "1rem",
                          lg: "0rem",
                        },
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: {
                          xs: "column",
                          md: "column",
                          sm: "column",
                          lg: "row",
                        },
                        width: {
                          xs: "100%",
                          md: "100%",
                          sm: "100%",
                          lg: "100%",
                        },
                      }}
                      gap="1rem"
                    >
                      <a
                        href={data?.url_vcf_file}
                        target="_blank"
                        rel="noreferrer"
                        style={{
                          'display':'flex',
                          'alignItems':'center',
                          'gap':8,
                          'justifyContent':'center',
                          'borderRadius':'8px',
                          'width':'100%',
                          'padding':'14px',
                          'color':'#FFF',
                          'backgroundColor':'#964CFC',
                          'textDecoration':'none',
                        }}
                      >
                        <img src={ContactList} width={20} height={20}/>
                        <Typography fontSize="1rem">
                          Enregistrer aux contacts
                        </Typography>
                      </a>

                      <Button
                        onClick={showForm}
                        variant="secondary"
                        sx={{
                          alignItems: "center",
                          width: {
                            xs: "100%",
                            md: "100%",
                            sm: "100%",
                            lg: "100%",
                          },
                          borderColor: '#964CFC',
                          borderRadius: '8px',
                          padding:'11px',
                          gap:.5
                        }}
                      >
                        <img src={Send} width={25} height={20}/>
                        <Typography variant='p' fontSize={'1rem'} fontWeight={400}> Envoyer vos coordonnées</Typography>
                      </Button>
                    </Box>
                  </Box>

                  {/* Links & Document */}
                  <Box sx={{ maxWidth: { xs: "100%" }, width: { md: "100%" }, padding: '0px 16px' }}>
                    <Typography variant="subtitle1" color="initial" fontWeight={500} sx={{padding:'0.5rem'}}>Liens & Document</Typography>
                    {(links.length !== 0 || docs.length !== 0) && (
                      <Box
                        width="100%"
                        sx={{
                          padding: "0.5rem",
                        }}
                      >
                        {links && (
                          <Box>
                            {/* Modal add link */}
                            {/* modal update link */}
                            {links?.map((l) => (
                              <Box
                                key={l.id}
                                display="flex"
                                flexDirection="row"
                                justifyContent="space-between"
                                sx={{ alignItems: "center" }}
                              >
                                <Links
                                  hide={
                                    l.link.slug === "other" ? false : true
                                  }
                                  title={l.title}
                                  icon={l.link.slug === "other" ? Globe : l.link.url_logo}
                                  value={l.value}
                                />

                              </Box>
                            ))}
                            {docs &&
                              docs?.map((d) => (
                                <Box
                                  key={d.id}
                                  display="flex"
                                  flexDirection="row"
                                  justifyContent="space-between"
                                  sx={{ alignItems: "center" }}
                                >
                                  <Links
                                    title={d.title}
                                    icon={Document}
                                    value={d.url_file}
                                  />
                                </Box>
                              ))}

                            {/* Modal add DOC */}

                            {/* end modal update link  */}
                          </Box>
                        )}
                      </Box>
                    )}
                  </Box>


                  {/* Footer */}
                  <Box
                    gap="0.5rem"
                    width="100%"
                    display="flex"
                    sx={{
                      alignItems: "center",
                      flexDirection: {
                        sm: "column-reverse",
                        md: "column-reverse",
                        lg: "row",
                        xs: "column-reverse",
                      },
                      justifyContent: {
                        sm: "center",
                        md: "center",
                        xs: "center",
                      },
                    }}
                  >
                    <Box
                      onClick={() => window.open("https://bdigitall.co")}
                      sx={{
                        marginTop: {
                          xs: "1rem",
                          sm: "1rem",
                          md: "1rem",
                          lg: "0",
                        },
                        cursor: "pointer",
                      }}
                    >
                      <img 
                        alt="icon"
                        src={bdigitall}
                        style={{
                          width: {
                            xs: '10px',
                            sm: '10px',
                            md: '30px',
                            lg: '30px',
                            xl: '30px'
                          }
                        }}
                      />
                    </Box>
                  </Box>

                  </Box>

                  <Formik
                    enableReinitialize={true}
                    initialValues={{
                      email: form.email,
                      function:form.function,
                      last_name: form.last_name,
                      first_name: form.first_name,
                      phone: form.phone,
                      company_name: form.company_name,
                      policy: policy,
                    }}
                    onSubmit={(values, actions) => {
                      onSubmitContact(values, actions);
                    }}
                  >
                    {({ values, handleChange }) => (
                      <Modal open={openForm} onClose={() => setOpenForm(false)}>
                        <Form>
                        <Box
                          style={modalStyle}
                        >
                          {/* title + close icon */}
                          <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                            <Typography variant="h6" fontWeight={400}>
                              Laissez votre contact
                            </Typography>
                            <Box onClick={() => setOpenForm(false)} sx={{cursor:'pointer'}}>
                              <CloseIcon/>
                            </Box>
                          </Box>

                          <Box mt={2} mb={3} >
                          <FormControl>
                            <Box display={'flex'} gap={1} alignItems={'center'}>
                              <IOSSwitch
                                checked={value}
                                onChange={handleChangeTab}
                                inputProps={{ 'aria-label': 'iOS style switch' }}
                              />
                              <Typography variant='subtitle1' sx={{fontSize:'1rem', fontWeight:300, color: colors.carbon_black}} id="value">Déja Client</Typography>
                            </Box>
                            </FormControl>
                          <ErrorMessage name="value" component={MzErrorText} />
                          </Box>

                        { value === false && <>

                          {/* prenom */}
                          <Box
                            marginRight="1rem"
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              marginBottom: "1.3rem",
                              gap:1
                            }}
                          >
                            <label style={{fontSize:15, color: colors.carbon_black}}>Prénom*</label>
                            <Field
                              name="first_name"
                              as={TextField}
                              variant="outlined"
                              placeholder="Prénom"
                              size="small"
                              InputProps={{
                                style: {
                                  backgroundColor: '#F5F5F5',
                                },
                              }}
                            />
                            <ErrorMessage
                              name="first_name"
                              component={MzErrorText}
                            />
                          </Box>

                          {/* nom */}
                          <Box
                            marginRight="1rem"
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              marginBottom: "1.3rem",
                              gap:1
                            }}
                          >
                            <label style={{fontSize:15, color: colors.carbon_black}}>Nom*</label>
                            <Field
                              name="last_name"
                              as={TextField}
                              variant="outlined"
                              placeholder="Nom"
                              size="small"
                              InputProps={{
                                style: {
                                  backgroundColor: '#F5F5F5',
                                },
                              }}
                            />
                            <ErrorMessage
                              name="last_name"
                              component={MzErrorText}
                            />
                          </Box>

                          {/* phone */}
                          <Box
                            marginRight="1rem"
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              gap:1
                            }}
                          >
                            <label style={{fontSize:15, color: colors.carbon_black}}>Numéro de téléphone*</label>
                            <Field
                              name="phone"
                              as={TextField}
                              variant="outlined"
                              placeholder="+213 000 00 00 00"
                              size="small"
                              InputProps={{
                                style: {
                                  backgroundColor: '#F5F5F5',
                                },
                              }}
                            />
                            <ErrorMessage
                              name="phone"
                              component={MzErrorText}
                            />
                          </Box>

                          {/* more */}
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <List>
                              <ListItem onClick={toggleCollapse} sx={{cursor:'pointer'}}>
                                <Typography variant="p" fontSize={'.8rem'} color={colors.gray}>
                                  Afficher plus de détails
                                </Typography>
                                {isCollapsed ? <Add sx={{'color':colors.gray}} /> : <ExpandLess sx={{'color':colors.gray}} />}
                              </ListItem>
                              <Collapse sx={{width:'100%', margin:0, padding:0}} in={!isCollapsed} timeout="auto" unmountOnExit>
                                <ListItem sx={{display:'flex', flexDirection:'column',alignItems:'start', justifyContent:'center', gap:1}}>
                                <label style={{fontSize:15, color: colors.carbon_black}}>Email (optionnel)</label>
                                <Field
                                  name="email"
                                  as={TextField}
                                  variant="outlined"
                                  placeholder="example@email.com"
                                  size="small"
                                  fullWidth
                                  InputProps={{
                                    style: {
                                      backgroundColor: '#F5F5F5',
                                    },
                                  }}
                                />
                                </ListItem>
                                <ListItem sx={{display:'flex', flexDirection:'column',alignItems:'start', justifyContent:'start', gap:1}}>
                                <label style={{fontSize:15, color: colors.carbon_black}}>Nom de l'entreprise (optionnel)</label>
                                <Field
                                  name="company_name"
                                  as={TextField}
                                  variant="outlined"
                                  placeholder="Mon Entreprise"
                                  size="small"
                                  fullWidth
                                  InputProps={{
                                    style: {
                                      backgroundColor: '#F5F5F5',
                                    },
                                  }}
                                />
                                </ListItem>
                                <ListItem sx={{display:'flex', flexDirection:'column',alignItems:'start', justifyContent:'start', gap:1}}>
                                <label style={{fontSize:15, color: colors.carbon_black}}>Fonction (optionnel)</label>
                                <Field
                                  name="function"
                                  as={TextField}
                                  variant="outlined"
                                  placeholder="ex: CEO"
                                  size="small"
                                  fullWidth
                                  InputProps={{
                                    style: {
                                      backgroundColor: '#F5F5F5',
                                    },
                                  }}
                                />
                                </ListItem>
                              </Collapse>
                            </List>
                          </Box>

                          {/* policy */}
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "start",
                              marginBottom: "1.3rem",
                            }}
                          >
                            <Checkbox
                              name="policy"
                              required
                              variant="outlined"
                              label="Condition et Politique"
                              size="large"
                              sx={{
                                '&.Mui-checked': {
                                  color: '#964CFC',
                                },
                                '&.Mui-checked .MuiSvgIcon-root': {
                                  fill: '#964CFC',
                                },
                              }}
                            />
                            <Typography variant="subtitle1" fontSize={13} fontWeight={300}>
                              En soumettant, j'accepte la politique de confidentialité et le traitement de mes coordonnées. Vous pouvez révoquer ce consentement à tout moment.
                            </Typography>
                            <ErrorMessage
                              name="policy"
                              component={MzErrorText}
                            />
                          </Box>

                          {isError && <p style={{'color':`${colors.mjRedOutline}`}} className=" col-start-[span_1] md:col-start-[span_2] lg:col-start-[span_2]">Veuillez remplir les champs obligatoire </p>}
                          </>
                        }

                        { value === true && <>
                          {/* email */}
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              marginBottom: "1.3rem",
                              gap:1
                            }}
                          >
                            <label style={{fontSize:15}}>Email*</label>
                            <Field
                              name="email"
                              as={TextField}
                              variant="outlined"
                              placeholder="example@email.com"
                              size="small"
                              fullWidth
                              InputProps={{
                                style: {
                                  backgroundColor: '#F5F5F5',
                                },
                              }}
                            />
                            {isError && <p style={{'color':`${colors.mjRedOutline}`}} className=" col-start-[span_1] md:col-start-[span_2] lg:col-start-[span_2]">{ErrorMsg}</p>}
                          </Box>
                          
                          {/* policy */}
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "start",
                              justifyContent:'start',
                              marginBottom: "1.3rem",
                            }}
                          >
                            <Checkbox
                              name="policy"
                              required
                              variant="outlined"
                              label="Condition et Politique"
                              size="large"
                              sx={{
                                '&.Mui-checked': {
                                  color: '#964CFC',
                                },
                                '&.Mui-checked .MuiSvgIcon-root': {
                                  fill: '#964CFC',
                                },
                              }}
                            />
                            <Typography variant="subtitle1" fontSize={13} fontWeight={300}>
                              En soumettant, j'accepte la politique de confidentialité et le traitement de mes coordonnées. Vous pouvez révoquer ce consentement à tout moment.
                            </Typography>                           
                            <ErrorMessage
                              name="policy"
                              component={MzErrorText}
                            />
                          </Box>
                          </>
                        }

                          <Box
                            sx={{
                              display: "flex",
                              gap: "0.5rem",
                              flexDirection: "column",
                            }}
                          >
                            <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
                                { sending ?
                                <CircularProgress/>
                                :
                                <Button
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  width: {
                                    xs: "100%",
                                    md: "100%",
                                    sm: "100%",
                                    lg: "100%",
                                  },
                                  backgroundColor:'#964CFC',
                                  border: 'none',
                                  borderRadius: '16px',
                                  padding: '10px 0px',
                                  fontWeight:500
                                }}
                                disabled={sending}
                                variant="primary"
                                type="submit"
                              >
                                Envoyer
                              </Button> 
                                }
                            </Box>
                          </Box>
                        </Box>
                        </Form>
                      </Modal>
                    )}
                  </Formik>

                </Box>
              </Container>
            </Box>
          ) : (
            <Box>
              <Container
                sx={{
                  minHeight: "100vh",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: {
                    xs: "center",
                    md: "center",
                    sm: "center",
                    lg: "center",
                  },
                }}
                maxWidth="sm"
              >
                <img
                  style={{ margin: "1rem 0rem" }}
                  src={bdigitall}
                  alt="bdigitall logo"
                />
                <Box>
                  <Box
                    display="flex"
                    flexDirection="column"
                    sx={{
                      alignItems: "center",
                      backgroundColor: "#F9F6FF",
                      borderRadius: "2rem",
                      padding: "3rem",
                    }}
                  >
                    <Typography
                      marginBottom="1rem"
                      sx={{ textAlign: "center" }}
                    >
                      {error ? "Carte Inactive" : "Aucun utilisateur trouvé"}
                    </Typography>
                    <Avatar
                      sx={{
                        position: "relative",
                        borderRadius: "20px",
                        width: "12rem",
                        height: "11rem",
                        border: "1px solid #C9C5D2",
                        marginBottom: "1rem",
                      }}
                      variant="rounded"
                    >
                      {qrIconin ? (
                        <img
                          alt="icon"
                          src={qrIconin}
                          style={{
                            width: "12rem",
                            objectFit: "fill",
                            objectPosition: "50% 50%",
                          }}
                        />
                      ) : null}
                    </Avatar>
                  </Box>
                </Box>
                <a
                  href="https://www.bdigitall.co"
                  style={{ textDecoration: "none", color: "white" }}
                >
                  <Button sx={{ margin: "1rem 0rem" }} variant="primary">
                    Redirection vers notre site
                  </Button>
                </a>
              </Container>
            </Box>
          )}
        </>
      ) : null}
    </Box>
  );
}

export default AbonneeApercue;
