import React from "react";
import { Box, Typography, Card } from "@mui/material";

function IconLink({ icon, title, hide, value }) {
  // Ensure the URL has a protocol
  const fullUrl = value.startsWith('http://') || value.startsWith('https://')
    ? value
    : `https://${value}`;

  return (
    <Box sx={{ display: `${hide ? "none" : "flex"}` }} flexDirection="row" gap="0.5rem" alignItems="center">
      <a target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none", color: "black" }} href={fullUrl}>
        <Box className="well" display="flex" sx={{ alignItems: "center", justifyContent: "center", border:'.3px solid #E0E0E0', padding: "12px", borderRadius: "50%" }}>
          <img width="20px" height="20px" src={icon} alt={`${title} icon`} />
        </Box>
      </a>
      <Box>
        <Typography>
          <a target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none", color: "black" }} href={fullUrl}>
            {title}
          </a>
        </Typography>
      </Box>
    </Box>
  );
}

export default IconLink;
