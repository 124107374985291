import Typography from '@mui/material/Typography'
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from '../../hooks/useAuthContext';
import { ToastContainer, toast } from "react-toastify";
import API from '../../api/Api';
import { Box, Button, CircularProgress, colors } from '@mui/material';
import { Delete, Edit, EditLocation, Group } from '@mui/icons-material';

const PagesGroups = () => {
    const { user } = useAuthContext();
    const access_token = user.access_token;
    const idEntreprise = user?.user?.enterprise?.id;
    const navigate = useNavigate();
    const [companies,setCompanies] = useState([])
    const [group,setGroup] = useState([])

    const getGroups = ()=>{
        API.get('/bdisplay-groups', {
            headers: {
              "Content-type": "application/json",
              accept: "application/json",
              authorization: `Bearer ${access_token}`,
            },
            params:{ enterprise_id: idEntreprise}
          })
            .then((response) => {
              setGroup(response.data.data);
            })
            .catch((error) => {
              if (error.response.status === 401) {
                localStorage.removeItem("user");
                navigate("/login", { replace: true });
                navigate(0, { replace: true });
              }
              // console.log(error);
              if (error?.response?.status === 403) {
                toast.error("Une erreur est survenue");
              } else {
                toast.error(error?.response?.data?.message);
              }
            });
    }

    const deleteGroup = async (id)=>{
       await API.delete('/bdisplay-groups/' + id, {
            headers: {
              "Content-type": "application/json",
              accept: "application/json",
              authorization: `Bearer ${access_token}`,
            },
          })
            .then((response) => {
              if(response.status === 200){
                toast('operation success')
              }
              else{
                toast('operation success')
              }
            })
            .catch((error) => {
              if (error.response.status === 401) {
                localStorage.removeItem("user");
                navigate("/login", { replace: true });
                navigate(0, { replace: true });
              }
              // console.log(error);
              if (error?.response?.status === 403) {
                toast.error("Une erreur est survenue");
              } else {
                toast.error(error?.response?.data?.message);
              }
            });
    }

      //on page load
      useEffect(() => {
        getGroups()
      }, []);
    return ( 
        <main className='flex flex-col gap-4 w-full mt-12'>
            <Box maxWidth={'lg'} display={'flex'} alignItems={'center'} justifyContent={'space-between'} marginBottom={2}>
                <Box display={'flex'} flexDirection={'column'} alignItems={'start'} justifyContent={'center'} gap={3}>
                    <Typography variant="h6" fontWeight={500}>List Des Groupes</Typography>
                </Box>
                <Button variant='primary' onClick={()=> navigate('/admin/groups/add')}>Nouveau Group</Button>
            </Box>
            {!group ? <h3><CircularProgress/></h3> : group.length === 0 ? <h3>Aucun group</h3> :
            
            group.map((grp,index) =>(
                <span key={index} style={{display:'flex',alignItems:'center',justifyContent:'space-between',padding:' 12px 24px',borderRadius:'8px',boxShadow: '4px 17px 21px -4px rgba(0,0,0,0.15)','backgroundColor':colors.blueGrey}}>
                
                <h4 className='font-medium flex gap-2 items-center'>
                    {grp.identification}
                    <Group/> 
                    {grp.name}
                </h4>
                <div className='flex items-center gap-2'>
                    <button onClick={()=> navigate('/admin/groups/'+ grp.id)} style={{backgroundColor: colors.purple[800],padding:'8px',color:'white',borderRadius:'8px'}}>
                          <Edit/>
                    </button>
                    <button onClick={()=>deleteGroup(grp.id)} style={{backgroundColor: colors.purple[600],padding:'8px',color:'white',borderRadius:'8px'}}>
                          <Delete/>
                    </button>                
                </div>
            </span>
            ))}
        </main>
     );
}
 
export default PagesGroups;