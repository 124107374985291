import React from "react";
import { useState, useEffect, useRef } from "react";
import {
  Box,
  TextField,
  Button,
  Typography,
  CircularProgress,
  Autocomplete,
  IconButton,
  Avatar,
  Modal,
} from "@mui/material";
import { colors } from "../../theme/Colors";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import { Stack } from "@mui/system";
import Cropper from "react-easy-crop";
import getCroppedImg from "../../utils/cropImage";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";

import Api from "../../api/Api";
import { Urls } from "../../api/Urls";
import * as Yup from "yup";
import MzErrorText from "../../components/ui/MzErrorText";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useAuthContext } from "../../hooks/useAuthContext";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import userIcon from "../../imgs/user.png";
import "react-toastify/dist/ReactToastify.css";
import MzChip from "../../components/ui/MzChip";
import IconLink from "../../components/ui/IconLink";
import ConfirmationModal from "../../components/ui/ConfirmationModal";
import MyIconButton from "../../components/ui/MyIconButton";
import { ModalStyle } from "../../theme/ModalStyles";
import FileIcon from "../../imgs/file.svg";
import moment from "moment";

function CarteAbonneDetails() {
  const { user } = useAuthContext();
  const access_token = user.access_token;

  //is empty object
  function isObjEmpty(obj) {
    return Object.keys(obj).length === 0;
  }

  //allow "other" field add
  const [allowOtherField, setAllowOtherField] = useState();

  //location settings (get the id from onClick table)
  const { id } = useParams();

  //File config
  const FILE_SIZE = 5000 * 1024;
  const SUPPORTED_FORMATS = [
    "image/jpg",
    "image/jpeg",
    "image/png",
    "image/svg+xml",
    "image/webp",
  ];

  //validation schema profile picture
  const validationSchemaProfilePicture = Yup.object({
    profile_picture: Yup.mixed()
      .required("Veuillez télécharger un fichier")
      .test(
        "fileSize",
        "Fichier volumineux",
        (value) => value && value.size <= FILE_SIZE
      )
      .test(
        "fileFormat",
        "Format du fichier invalide",
        (value) => value && SUPPORTED_FORMATS.includes(value.type)
      ),
    ImageWidth: Yup.number().min(191, "Largeur minimale de 192 pixels"),
    ImageHeight: Yup.number().min(191, "Hauteur minimale de 192 pixels"),
  });
  const navigate = useNavigate();
  const [selectedLinkToDelete, setSelectedLinkToDelete] = useState();
  const [selectedDocToDelete, setSelectedDocToDelete] = useState();

  const [image, setImage] = useState();
  const [data, setData] = useState();
  const [links, setLinks] = useState();
  const [possibleLinksList, setPossibleLinksList] = useState();
  const [docs, setDocs] = useState();
  const [docToAddName, setDocToAddName] = useState();

  //trigger reload infos
  const [didUpdate, setDidUpdate] = useState(false);
  const [didUpdateLink, setDidUpdateLink] = useState(false);
  const [didDeleteLink, setDidDeleteLink] = useState(false);
  const [didAddLink, setDidAddLink] = useState(false);
  const [didDeleteDoc, setDidDeleteDoc] = useState(false);
  const [didAddDoc, setDidAddDoc] = useState(false);

  //modal config
  const [openAddLink, setOpenAddLink] = useState(false);
  const [openUpdate, setOpenUpdate] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [openDeleteDoc, setOpenDeleteDoc] = useState(false);
  const [openAddDoc, setOpenAddDoc] = useState(false);

  //links
  const [linksArrayLength, setLinksArrayLength] = useState(0);

  //add link modal
  const handleOpenAddLink = () => {
    if (data?.id === null || undefined) {
      toast.error("Aucune ligne séléctionnée");
      return;
    }
    setOpenAddLink(true);
  };
  const handleCloseAddLink = () => setOpenAddLink(false);

  //delete link modal
  const handleOpenDelete = () => {
    setOpenDelete(true);
  };
  const handleCloseDelete = () => setOpenDelete(false);

  //delete document modal
  const handleOpenDeleteDoc = () => {
    setOpenDeleteDoc(true);
  };
  const handleCloseDeleteDoc = () => setOpenDeleteDoc(false);

  //update link modal
  const handleOpenUpdate = () => {
    setOpenUpdate(true);
  };
  const handleCloseUpdate = () => setOpenUpdate(false);

  //add doc modal
  const handleOpenAddDoc = () => {
    setOpenAddDoc(true);
  };
  const handleCloseAddDoc = () => setOpenAddDoc(false);
  const [change, setChange] = useState(false);
  //tab config
  const [inLinks, setInLinks] = useState(true);
  const [inDocuments, setInDocuments] = useState(false);
  const [currentVariantLink, setCurrentVariantLink] = useState("primary");
  const [currentVariantDocument, setCurrentVariantDocument] =
    useState("secondary");

  const handleButtonVariantChangeLink = () => {
    if (
      (currentVariantDocument === "secondary" &&
        currentVariantLink === "primary") ||
      (currentVariantDocument === "primary" &&
        currentVariantLink === "secondary")
    ) {
      setCurrentVariantLink("primary");
      setCurrentVariantDocument("secondary");
    }
  };
  const handleButtonVariantChangeDocument = () => {
    if (
      (currentVariantLink === "secondary" &&
        currentVariantDocument === "primary") ||
      (currentVariantLink === "primary" &&
        currentVariantDocument === "secondary")
    ) {
      setCurrentVariantDocument("primary");
      setCurrentVariantLink("secondary");
    }
  };

  //initial values link
  const initialValuesAddLink = {
    link_id: "",
    value: "",
    title: "",
  };

  //initial values doc
  const initialValuesAddDoc = {
    title: "",
    user_ids: [id] ?? "",
    document: "",
  };

  //validation schema link fields
  const validationSchemaAddLink = Yup.object({
    link_id: Yup.string().required("Ce champ est obligatoire"),
    value: Yup.string().required("Ce champ est obligatoire").url(),
    title: Yup.string().required("Ce champ est obligatoire"),
  });

  //validation schema add document fields
  const validationSchemaAddDoc = Yup.object({
    title: Yup.string().required("Ce champ est obligatoire"),
    document: Yup.mixed()
      .required("Veuillez selectionner un fichier")
      .test(
        "fileSize",
        "Fichier volumineux",
        (value) => value && value.size <= FILE_SIZE
      ),
  });

  // eliminate empty data
  function nonEmptyObject(obj) {
    let tempObj = obj;
    for (const propName in tempObj) {
      if (
        (typeof tempObj[propName] === "object" &&
          Object.keys(tempObj[propName]).length === 0) ||
        tempObj[propName] === null ||
        tempObj[propName] === ""
      ) {
        delete tempObj[propName];
      }
    }
    return tempObj;
  }
  const imageProfile = useRef();

  //on page load (load user details)
  useEffect(() => {
    Api.get(Urls.GET_USER_ACCOUNT_DETAILS + `/${id}/account-details`, {
      headers: {
        "Content-type": "application/json",
        accept: "application/json",
        authorization: `Bearer ${access_token}`,
      },
    })
      .then((response) => {
        setData(response.data.user);
      })
      .catch((error) => {
        if (error.response.status === 403)
          toast.error("Une erreur est survenue");
        else toast.error(error.response.data.message);
        if (error.response.status === 401) {
          localStorage.removeItem("user");
          navigate("/login", { replace: true });
          navigate(0, { replace: true });
        }
      });
  }, [didUpdate, change, access_token, id, navigate]);

  //on page load (get DOCS)
  useEffect(() => {
    Api.get(Urls.GET_USER_DOCUMENTS + `/${id}/documents`, {
      headers: {
        "Content-type": "application/json",
        accept: "application/json",
        authorization: `Bearer ${access_token}`,
      },
    })
      .then((response) => {
        setDocs(response.data.documents);
      })
      .catch((error) => {
        if (error.response.status === 403)
          toast.error("Une erreur est survenue");
        else toast.error(error.response.data.message);
        if (error.response.status === 401) {
          localStorage.removeItem("user");
          navigate("/login", { replace: true });
          navigate(0, { replace: true });
        }
      });
  }, [didDeleteDoc, didAddDoc, change, access_token, id, navigate]);

  //on page load (get LINKS)
  useEffect(() => {
    Api.get(Urls.GET_USER_LINKS + `/${id}/userLinks`, {
      headers: {
        "Content-type": "application/json",
        accept: "application/json",
        authorization: `Bearer ${access_token}`,
      },
    })
      .then((response) => {
        setLinks(response.data.user_links);
        setLinksArrayLength(response.data.user_links.length);
      })
      .catch((error) => {
        if (error.response.status === 403)
          toast.error("Une erreur est survenue");
        else toast.error(error.response.data.message);
        if (error.response.status === 401) {
          localStorage.removeItem("user");
          navigate("/login", { replace: true });
          navigate(0, { replace: true });
        }
      });
  }, [
    didDeleteLink,
    didAddLink,
    didUpdateLink,
    change,
    access_token,
    id,
    navigate,
  ]);

  //submit update user
  const onSubmitUpdateUser = (values) => {
    let realValues = nonEmptyObject(values);
    Api.patch(
      Urls.UPDATE_USER_INFOS_ENTREPRISE + `${data?.id}`,
      {
        first_name: realValues.first_name,
        last_name: realValues.last_name,
        department: realValues.department,
        function: realValues.function,
        phone_number: realValues.phone_number,
        country_name: realValues.country_name,
        city_name: realValues.city_name,
        postal_code: realValues.postal_code,
        address: realValues.address,
      },
      {
        headers: {
          "Content-type": "application/json",
          authorization: `Bearer ${access_token}`,
        },
      }
    )
      .then((response) => {
        if (response.status === 200) {
          setDidUpdate(!didUpdate);
          toast.success("Informations du collaborateur changé avec succès");
        }
      })
      .catch((error) => {
        if (error.response.status === 403)
          toast.error("Une erreur est survenue");
        else toast.error(error.response.data.message);
        if (error.response.status === 401) {
          localStorage.removeItem("user");
          navigate("/login", { replace: true });
          navigate(0, { replace: true });
        }
      });
  };

  //update profile picture
  const [onUploadImage, setOnUploadImage] = useState(false);
  const onSubmitUpdateProfilePicture = (values) => {
    setOnUploadImage(true);

    Api.post(
      Urls.UPDATE_PROFILE_PICTURE + `/${data?.id}/profilePicture`,
      {
        profile_picture: values.profile_picture,
      },
      configs
    )
      .then((response) => {
        if (response.status === 200) {
          toast.success(response.data.message);
          setOnUploadImage(false);
          setProgress(0);
        }
        setChange(!change);
      })
      .catch((error) => {
        setOnUploadImage(false);
        if (error.response.status === 403)
          toast.error("Une erreur est survenue");
        else toast.error(error.response.data.message);
        if (error.response.status === 401) {
          localStorage.removeItem("user");
          navigate("/login", { replace: true });
          navigate(0, { replace: true });
        }
      });
  };

  //get list possible links
  useEffect(() => {
    Api.get(Urls.GET_POSSIBLE_LINKS, {
      headers: {
        "Content-type": "application/json",
        accept: "application/json",
        authorization: `Bearer ${access_token}`,
      },
    })
      .then((response) => {
        setPossibleLinksList(response.data.links);
      })
      .catch((error) => {
        if (error.response.status === 403)
          toast.error("Une erreur est survenue");
        else toast.error(error.response.data.message);
        if (error.response.status === 401) {
          localStorage.removeItem("user");
          navigate("/login", { replace: true });
          navigate(0, { replace: true });
        }
      });
  }, [change, access_token, navigate]);

  //add link
  const addLink = (values) => {
    Api.post(
      Urls.CREATE_USER_LINK + `/${id}/userLinks`,
      {
        link_id: values.link_id,
        value: values.value,
        title: values.title,
      },
      {
        headers: {
          "Content-type": "application/json",
          authorization: `Bearer ${access_token}`,
        },
      }
    )
      .then((response) => {
        if (response.status === 201) {
          handleCloseAddLink();
          setDidAddLink(!didAddLink);
          toast.success("Lien ajouté avec succès");
        }
      })
      .catch((error) => {
        if (error.response.status === 403)
          toast.error("Une erreur est survenue");
        else toast.error(error.response.data.message);
        if (error.response.status === 401) {
          localStorage.removeItem("user");
          navigate("/login", { replace: true });
          navigate(0, { replace: true });
        }
      });
  };
  const [loadingDoc, setLoadingDoc] = useState(false);
  const [progress, setProgress] = useState(null);
  //add docs

  var configs = {
    headers: {
      "Content-type": "multipart/form-data",
      authorization: `Bearer ${access_token}`,
    },
    onUploadProgress: function (progressEvent) {
      var percentCompleted = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total
      );
      setProgress(percentCompleted);
    },
  };
  const addDoc = (values) => {
    setLoadingDoc(true);

    Api.post(
      Urls.ADD_USER_DOCUMENT,
      {
        title: values.title,
        user_ids: [id],
        document: values.document,
      },
      configs
    )
      .then((response) => {
        if (response.status === 201) {
          setLoadingDoc(false);
          handleCloseAddDoc();
          toast.success("Document ajouté avec succès");
          setDocToAddName("");
          setDidAddDoc(!didAddDoc);
        }
      })
      .catch((error) => {
        if (error.response.status === 403)
          toast.error("Une erreur est survenue");
        else toast.error(error.response.data.message);
        setLoadingDoc(false);
        if (error.response.status === 401) {
          localStorage.removeItem("user");
          navigate("/login", { replace: true });
          navigate(0, { replace: true });
        }
      });
  };

  //update link
  const updateLink = (values, id_link) => {
    //console.log("ABOUT TO UPDATE LINK WITH ID :", id_link);
    //console.log("VALUES TO UPDATE WITH :", values);
    Api.patch(
      Urls.DELETE_USER_LINK + `/${id_link}`,
      {
        link_id: values.link_id,
        value: values.value,
        title: values.title,
      },
      {
        headers: {
          "Content-type": "application/json",
          authorization: `Bearer ${access_token}`,
        },
      }
    )
      .then((response) => {
        //console.log("RESPONSE UPDATE LINK", response);

        if (response.status === 200) {
          //console.log(response);
          handleCloseUpdate();
          setDidUpdateLink(!didUpdateLink);
          toast.success("Lien modifié avec succès");
        }
      })
      .catch((error) => {
        //console.log("RESPONSE", error.response.data.message);
        if (error.response.status === 403)
          toast.error("Une erreur est survenue");
        else toast.error(error.response.data.message);
        if (error.response.status === 401) {
          localStorage.removeItem("user");
          navigate("/login", { replace: true });
          navigate(0, { replace: true });
        }
      });
  };

  //delete link
  const deleteLink = (id_link) => {
    //console.log("access_token", access_token);
    Api.delete(
      Urls.DELETE_USER_LINK + `/${id_link}`,

      {
        headers: {
          "Content-type": "application/json",
          authorization: `Bearer ${access_token}`,
        },
      }
    )
      .then((response) => {
        //console.log("RESPONSE DELETE LINK", response);

        if (response.status === 200) {
          //console.log(response);
          setDidDeleteLink(!didDeleteLink);
          toast.success(response.data.message);
        }
      })
      .catch((error) => {
        //console.log("RESPONSE", error.response.data.message);
        if (error.response.status === 403)
          toast.error("Une erreur est survenue");
        else toast.error(error.response.data.message);
        if (error.response.status === 401) {
          localStorage.removeItem("user");
          navigate("/login", { replace: true });
          navigate(0, { replace: true });
        }
      });
  };

  //delete link
  const deleteDoc = (id_doc) => {
    //console.log("access_token", access_token);
    Api.delete(
      Urls.DELETE_USER_DOCUMENT + `/${id_doc}`,

      {
        headers: {
          "Content-type": "application/json",
          authorization: `Bearer ${access_token}`,
        },
      }
    )
      .then((response) => {
        //console.log("RESPONSE DELETE DOC", response);

        if (response.status === 200) {
          //console.log(response);
          setDidDeleteDoc(!didDeleteDoc);
          toast.success(response.data.message);
        }
      })
      .catch((error) => {
        //console.log("RESPONSE", error.response.data.message);
        if (error.response.status === 403)
          toast.error("Une erreur est survenue");
        else toast.error(error.response.data.message);
        if (error.response.status === 401) {
          localStorage.removeItem("user");
          navigate("/login", { replace: true });
          navigate(0, { replace: true });
        }
      });
  };
  const [openDeletePic, setOpenDeletePic] = useState(false);

  //crop image config
  const formikRef = useRef();
  const [format, setFormat] = useState("");
  const [onImage, setOnImage] = useState(false);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState();
  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    //console.log(croppedArea, croppedAreaPixels);
    setCroppedAreaPixels(croppedAreaPixels);
  };
  const onCrop = async () => {
    const croppedImage = await getCroppedImg(image, croppedAreaPixels, format);
    setImage(URL.createObjectURL(croppedImage));
    var file = new File(
      [croppedImage],
      `${Math.floor(Math.random() * 10000000000000000)}.${
        format.split("/")[1]
      }`,
      { lastModified: 1534584790000, type: [`${format}`] }
    );
    if (file && croppedImage) {
      setOnImage(false);
      formikRef.current.values.profile_picture = file;
      file = null;
    }
  };
  const styleCropper = {
    containerStyle: {
      positon: "absolute",
      top: "20px",
      margin: "0 auto",
      height: "60%",
      width: "60%",
    },
  };
  //delete photo de profil
  const deleteProfilePic = () => {
    Api.delete(Urls.DELETE_PROFILE_PIC + id + "/profilePicture", {
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${access_token}`,
      },
    })
      .then((res) => {
        toast.success(res.data.message);
        setChange(!change);
        setImage(null);
      })
      .catch((err) => {
        if (err.response.status === 403) toast.error("Une erreur est survenue");
        else toast.err(err.response.data.message);
        if (err.response.status === 401) {
          localStorage.removeItem("user");
          navigate("/login", { replace: true });
          navigate(0, { replace: true });
        }
      });
  };
  function capitalizeFirstLetter(string) {
    if (string) {
      return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    } else return "";
  }
  return (
    <Box>
      <Box>
        <Typography sx={{ marginBottom: "2rem", fontWeight: "500" }}>
          Détail du compte
        </Typography>
      </Box>
      {/* Header Section */}
      <Box sx={{ marginBottom: "2rem" }}>
        <Box
          display="flex"
          sx={{
            flexDirection: { md: "row", xs: "column" },
            gap: "2rem",
          }}
        >
          <Box>
            {/* formik 2 logo */}
            <Formik
              innerRef={formikRef}
              enableReinitialize={true}
              initialValues={{
                profile_picture: data?.url_profile_picture ?? "",
                ImageHeight: 192,
                ImageWidth: 192,
              }}
              validationSchema={validationSchemaProfilePicture}
              onSubmit={(values) => {
                onSubmitUpdateProfilePicture(values, access_token);
              }}
            >
              {({ values, setFieldValue, errors }) => (
                <Form>
                  <Box display="flex" flexDirection="row" gap="0.5rem">
                    <Box display="flex" flexDirection="column">
                      <Avatar
                        sx={{
                          position: "relative",
                          borderRadius: "20px",
                          width: "12rem",
                          height: "12rem",
                          border: "1px solid #C9C5D2",
                          marginBottom: "1rem",
                        }}
                        variant="rounded"
                      >
                        {image ? (
                          <img
                            alt="profile-pic"
                            ref={imageProfile}
                            src={image}
                            style={{
                              width: "12rem",
                              height: "12rem",
                              objectFit: "fill",
                              objectPosition: "50% 50%",
                            }}
                          />
                        ) : data?.url_profile_picture ? (
                          <img
                            alt="profile-pic"
                            ref={imageProfile}
                            src={data?.url_profile_picture}
                            style={{
                              width: "12rem",
                              height: "12rem",
                              objectFit: "fill",
                              objectPosition: "50% 50%",
                            }}
                          />
                        ) : (
                          <img
                            alt="profile-pic"
                            src={userIcon}
                            style={{
                              width: "12rem",
                              height: "12rem",
                              objectFit: "fill",
                              objectPosition: "50% 50%",
                            }}
                          />
                        )}
                        <IconButton
                          sx={{
                            zIndex: "33",
                            position: "absolute",
                            right: "0.5rem",
                            bottom: "0.5rem",
                            bgcolor: `${colors.main_purple}`,
                            "&:hover": {
                              bgcolor: `${colors.main_purple_hover}`,
                            },
                          }}
                          component="label"
                        >
                          <input
                            hidden
                            id="profile_picture"
                            name="profile_picture"
                            type="file"
                            onChange={(event) => {
                              setFieldValue(
                                "profile_picture",
                                event.target.files[0]
                                  ? event.target.files[0]
                                  : null
                              );
                              if (event.target.files[0]) {
                                setOnImage(true);
                                setImage(
                                  URL.createObjectURL(event.target.files[0])
                                );
                                setFormat(event.target.files[0].type);
                              }
                            }}
                          />
                          <AddRoundedIcon htmlColor="white" />
                        </IconButton>
                      </Avatar>
                      <Modal
                        sx={{ backgroundColor: "#3f3f3f" }}
                        open={onImage}
                        onClose={() => {
                          setImage(null);
                          formikRef.current.values.profile_picture = "";
                          setOnImage(false);
                        }}
                      >
                        <Box height="100%" margin="0 auto">
                          <Box
                            sx={{
                              height: "80%",
                              width: "80%",
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <Box
                              height="100%"
                              width="100%"
                              display="flex"
                              flexDirection="column"
                            >
                              <Cropper
                                style={styleCropper}
                                image={image}
                                crop={crop}
                                zoom={zoom}
                                aspect={1 / 1}
                                onCropChange={setCrop}
                                onCropComplete={onCropComplete}
                                onZoomChange={setZoom}
                              />
                            </Box>
                          </Box>
                          <Box
                            position="absolute"
                            bottom="5%"
                            left="0"
                            right="0"
                            gap="1rem"
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                            justifyContent="center"
                          >
                            <MzErrorText>
                              {errors.profile_picture
                                ? errors.profile_picture
                                : null}
                            </MzErrorText>
                            <Box
                              width="fit-content"
                              display="flex"
                              flexDirection="row"
                              gap="1rem"
                              alignItems="center"
                            >
                              <Button
                                disabled={
                                  errors?.profile_picture ? true : false
                                }
                                onClick={onCrop}
                                variant="primary"
                              >
                                Confirmer
                              </Button>
                              <Button
                                variant="primary"
                                onClick={() => {
                                  setImage(null);
                                  formikRef.current.values.profile_picture = "";
                                  setOnImage(false);
                                }}
                              >
                                Annuler
                              </Button>
                            </Box>
                            <Box
                              display="flex"
                              gap="0.5rem"
                              sx={{
                                flexDirection: {
                                  xs: "column",
                                  sm: "row",
                                  lg: "row",
                                  md: "row",
                                },
                                alignItems: "center",
                              }}
                            >
                              <Typography color="white">
                                Niveau de zoom :{" "}
                              </Typography>
                              <input
                                type="range"
                                min={1}
                                max={3}
                                step={0.1}
                                value={zoom}
                                onInput={(e) => setZoom(e.target.value)}
                              />
                            </Box>
                          </Box>
                        </Box>
                      </Modal>
                      <Box>
                        {errors.profile_picture ? (
                          <MzErrorText>{errors.profile_picture}</MzErrorText>
                        ) : null}
                        {values.ImageHeight < 191 ? (
                          <MzErrorText>
                            Hauteur minimale de 192 pixels
                          </MzErrorText>
                        ) : null}
                        {values.ImageWidth < 191 ? (
                          <MzErrorText>
                            Largeur minimale de 192 pixels
                          </MzErrorText>
                        ) : null}
                      </Box>{" "}
                      <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        gap="0.5rem"
                        sx={{ margin: "0.5rem 0rem 1rem 0rem" }}
                      >
                        <Box display="flex" gap="0.5rem" alignItems="center">
                          <Button variant="primary" type="submit">
                            Enregistrer la photo
                          </Button>
                          {data?.url_profile_picture && (
                            <IconButton
                              onClick={() => setOpenDeletePic(true)}
                              sx={{
                                zIndex: "33",
                                bgcolor: `${colors.bd_suspended}`,
                                "&:hover": {
                                  bgcolor: `${colors.bd_suspended_hover}`,
                                },
                              }}
                              component="label"
                            >
                              <DeleteRoundedIcon htmlColor="white" />
                            </IconButton>
                          )}
                        </Box>
                        <ConfirmationModal
                          title="Supprimer la photo de profile"
                          message="Êtes-vous sûr de bien vouloir supprimer la photo de profile  ?"
                          acceptLabel="oui"
                          refuseLabel="non"
                          open={openDeletePic}
                          onClose={() => setOpenDeletePic(false)}
                          acceptMethod={deleteProfilePic}
                          acceptMethodParams="SUSPENDED"
                        />

                        <Box>
                          {onUploadImage && (
                            <Box display="flex" alignItems="center" gap="1rem">
                              <CircularProgress size={30} />
                              <Typography
                                width="20px"
                                color={colors.main_purple}
                              >
                                {progress ? progress + "%" : null}
                              </Typography>
                            </Box>
                          )}
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Form>
              )}
            </Formik>
          </Box>
          <Box display="flex" flexDirection="column" gap="0.5rem">
            <Typography variant="h3">{`${capitalizeFirstLetter(
              data?.first_name.split(" ")[0]
            )} ${capitalizeFirstLetter(
              data?.first_name.split(" ")[1]
            )} ${data?.last_name.toUpperCase()}`}</Typography>
            <Typography variant="secondary">{`${data?.function}`}</Typography>
            <Box>
              {data?.status_card === "SUSPENDED" && (
                <MzChip name="C_SUSPENDED" />
              )}
              {data?.status_card === "ACTIVE" && <MzChip name="C_ACTIVE" />}
              {data?.status_card === "COMMANDED" && (
                <MzChip name="C_COMMANDED" />
              )}
            </Box>
            <Typography variant="secondary">
              {data?.expiration_date === null
                ? null
                : moment(data?.expiration_date).format() >=
                  moment(new Date()).format()
                ? `Carte valide jusqu'au ${
                    data?.expiration_date?.split("-")[2] +
                    "/" +
                    data?.expiration_date?.split("-")[1] +
                    "/" +
                    data?.expiration_date?.split("-")[0]
                  }`
                : `Carte expirée le ${
                    data?.expiration_date?.split("-")[2] +
                    "/" +
                    data?.expiration_date?.split("-")[1] +
                    "/" +
                    data?.expiration_date?.split("-")[0]
                  }`}
            </Typography>
          </Box>
        </Box>
      </Box>
      {/* End Header Section */}

      {/* INFOS + LINK/DOCS Section */}
      <Box
        display="flex"
        sx={{
          flexDirection: { lg: "row", xs: "column" },
          gap: "2rem",
        }}
      >
        {/* Mes informations Section*/}

        <Formik
          enableReinitialize={true}
          initialValues={{
            email: data?.email ?? "",
            first_name: data?.first_name ?? "",
            last_name: data?.last_name ?? "",
            department: data?.department ?? "",
            function: data?.function ?? "",
            phone_number: data?.phone_number ?? "",
            country_name: data?.country_name ?? "",
            city_name: data?.city_name ?? "",
            postal_code: data?.postal_code ?? "",
            address: data?.address ?? "",
            status_card: data?.status_card ?? "",
          }}
          //validationSchema={validationSchemaUser}
          onSubmit={(values) => {
            onSubmitUpdateUser(values, access_token);
          }}
        >
          {({ values, setFieldValue }) => (
            <Form>
              <Box maxWidth="500px">
                <Box>
                  <Typography mb="1rem">
                    Informations du collaborateur
                  </Typography>
                </Box>

                <Box
                  marginRight="1rem"
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    marginBottom: "1.3rem",
                  }}
                >
                  <Field
                    disabled
                    name="email"
                    as={TextField}
                    variant="outlined"
                    label="Email*"
                    size="small"
                  />
                  <ErrorMessage name="email" component={MzErrorText} />
                </Box>
                <Box
                  marginRight="1rem"
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    marginBottom: "1.3rem",
                  }}
                >
                  <Field
                    name="phone_number"
                    as={TextField}
                    variant="outlined"
                    label="Téléphone professionel"
                    size="small"
                  />
                  <ErrorMessage name="phone_number" component={MzErrorText} />
                </Box>
                <Box
                  marginRight="1rem"
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    marginBottom: "1.3rem",
                  }}
                >
                  <Field
                    name="department"
                    as={TextField}
                    variant="outlined"
                    label="Déoartement"
                    size="small"
                  />
                  <ErrorMessage name="department" component={MzErrorText} />
                </Box>
                <Box
                  marginRight="1rem"
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    marginBottom: "1.3rem",
                  }}
                >
                  <Field
                    name="function"
                    as={TextField}
                    variant="outlined"
                    label="Fonction"
                    size="small"
                  />
                  <ErrorMessage name="function" component={MzErrorText} />
                </Box>
                <Box
                  marginRight="1rem"
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    marginBottom: "1.3rem",
                  }}
                >
                  <Field
                    name="country_name"
                    as={TextField}
                    variant="outlined"
                    label="Pays"
                    size="small"
                  />
                  <ErrorMessage name="country_name" component={MzErrorText} />
                </Box>
                <Box
                  marginRight="1rem"
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    marginBottom: "1.3rem",
                  }}
                >
                  <Field
                    name="city_name"
                    as={TextField}
                    variant="outlined"
                    label="Ville"
                    size="small"
                  />
                  <ErrorMessage name="city_name" component={MzErrorText} />
                </Box>
                <Box
                  marginRight="1rem"
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    marginBottom: "1.3rem",
                  }}
                >
                  <Field
                    name="postal_code"
                    as={TextField}
                    variant="outlined"
                    label="Code postal"
                    size="small"
                  />
                  <ErrorMessage name="postal_code" component={MzErrorText} />
                </Box>
                <Box
                  marginRight="1rem"
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    marginBottom: "1.3rem",
                  }}
                >
                  <Field
                    name="address"
                    as={TextField}
                    variant="outlined"
                    label="Adresse"
                    size="small"
                  />
                  <ErrorMessage name="address" component={MzErrorText} />
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  gap: "0.5rem",
                  flexDirection: { md: "row", xs: "column" },
                }}
              >
                <Box>
                  <Button variant="primary" type="submit">
                    Enregistrer
                  </Button>
                </Box>
                {/*<Box sx={{ display: "flex", flexDirection: { md: "row", xs: "column" }, gap: "0.5rem" }}>
                  <Box>
                    <Button disabled={data?.status_card === "ACTIVE"} onClick={handleOpenActive} variant="primary">
                      Activer la carte
                    </Button>
                  </Box>
                  <Box>
                    <Button disabled={data?.status_card === "SUSPENDED"} onClick={handleOpenSuspend} variant="third">
                      Suspendre la carte
                    </Button>
                  </Box>
                </Box> */}
                <ToastContainer
                  style={{ top: "5rem" }}
                  position="top-right"
                  autoClose={5000}
                  hideProgressBar={false}
                  newestOnTop={false}
                  closeOnClick
                  rtl={false}
                  pauseOnFocusLoss
                  draggable
                  pauseOnHover
                  theme="dark"
                />
              </Box>
            </Form>
          )}
        </Formik>
        {/* End mes informations Section*/}

        {/* Links Docs Section */}

        <Box sx={{ maxWidth: { xs: "500px" }, width: { md: "500px" } }}>
          <Box sx={{ display: "flex", gap: "0.5rem", marginBottom: "1rem" }}>
            <Button
              onClick={() => {
                setInLinks(true);
                setInDocuments(false);
                handleButtonVariantChangeLink();
              }}
              variant={currentVariantLink}
            >
              Liens
            </Button>
            <Button
              onClick={() => {
                setInDocuments(true);
                setInLinks(false);
                handleButtonVariantChangeDocument();
              }}
              variant={currentVariantDocument}
            >
              Documents
            </Button>
          </Box>

          {inLinks && (
            <Box
              width="100%"
              sx={{
                backgroundColor: "#F9F6FF",
                borderRadius: "2rem",
                padding: "0.5rem",
              }}
            >
              {links?.map((l) => (
                <Box
                  key={l.id}
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <IconLink
                    title={l.title}
                    icon={l.link.url_logo}
                    value={l.value}
                  />
                  <Box>
                    <MyIconButton
                      sx={{ padding: "1rem" }}
                      name="edit"
                      onClick={() => {
                        setSelectedLinkToDelete(l);
                        if (l.link.slug === "other") {
                          setAllowOtherField(true);
                        } else {
                          setAllowOtherField(false);
                        }
                        handleOpenUpdate();
                      }}
                    />
                    <MyIconButton
                      sx={{ padding: "1rem" }}
                      name="delete"
                      onClick={() => {
                        setSelectedLinkToDelete(l);
                        handleOpenDelete();
                      }}
                    />
                  </Box>
                </Box>
              ))}

              <ConfirmationModal
                title="Supprimer un lien"
                message="Êtes-vous sûr de bien vouloir supprimer ce lien ?"
                acceptLabel="oui"
                refuseLabel="non"
                open={openDelete}
                onClose={handleCloseDelete}
                acceptMethod={deleteLink}
                acceptMethodParams={selectedLinkToDelete?.id}
              />

              {
                !data?'chargement':
                (data.enterprise.plan_id=='STARTER' &&  linksArrayLength<2)?
                <IconButton sx={{ margin: "0.5rem 1rem", bgcolor: `${colors.main_purple}`, "&:hover": { bgcolor: `${colors.main_purple_hover}` }, }} onClick={handleOpenAddLink} > <AddRoundedIcon htmlColor="white" /> </IconButton>:
                ((data.enterprise.plan_id=='SELLER' || data.enterprise.plan_id=='SMARTER' ) &&  linksArrayLength<3)?
                <IconButton sx={{ margin: "0.5rem 1rem", bgcolor: `${colors.main_purple}`, "&:hover": { bgcolor: `${colors.main_purple_hover}` }, }} onClick={handleOpenAddLink} > <AddRoundedIcon htmlColor="white" /> </IconButton>:
                (data.enterprise.plan_id=='WOLF_OF_WALL_STREET')?
                <IconButton sx={{ margin: "0.5rem 1rem", bgcolor: `${colors.main_purple}`, "&:hover": { bgcolor: `${colors.main_purple_hover}` }, }} onClick={handleOpenAddLink} > <AddRoundedIcon htmlColor="white" /> </IconButton>:
                // <p>Veuillez mettre à jour votre abonnement</p>
                null
              }
              {/* Modal add link
               */}
              <Modal open={openAddLink} onClose={handleCloseAddLink}>
                <Box sx={ModalStyle}>
                  <Typography fontSize="1.5rem" fontWeight="600" mb="2rem">
                    Ajouter un lien
                  </Typography>
                  {/* form add link */}
                  <Formik
                    initialValues={initialValuesAddLink}
                    validationSchema={validationSchemaAddLink}
                    onSubmit={addLink}
                  >
                    {({ values, setFieldValue }) => (
                      <Form>
                        <Box className="field" sx={{ marginBottom: "1rem" }}>
                          <Field
                            fullWidth
                            name="link_id"
                            component={Autocomplete}
                            options={possibleLinksList}
                            isOptionEqualToValue={(option, value) =>
                              option.id === value.id
                            }
                            getOptionLabel={(pl) =>
                              pl.title !== "Site Web" ? pl.title : "Autre"
                            }
                            onChange={(event, value) => {
                              if (value === null) {
                                value = {};
                                setFieldValue("title", "");
                                setFieldValue("link_id", "");
                              }

                              setFieldValue("link_id", value ? value.id : "");
                              setFieldValue("title", value ? value.title : "");

                              if (value.slug === "other") {
                                setAllowOtherField(true);
                                setFieldValue("title", value.title);
                              } else {
                                setAllowOtherField(false);
                              }
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                name="autocomplete"
                                label="Lien vers"
                              />
                            )}
                            size="small"
                          />
                          <ErrorMessage
                            name="link_id"
                            component={MzErrorText}
                          />
                        </Box>

                        {allowOtherField ? (
                          <Box className="field" sx={{ marginBottom: "1rem" }}>
                            <Field
                              name="title"
                              type="input"
                              label="Titre"
                              id="title"
                              as={TextField}
                              fullWidth
                              size="small"
                            />
                            <ErrorMessage
                              name="title"
                              component={MzErrorText}
                            />
                          </Box>
                        ) : (
                          ""
                        )}

                        <Box className="field" sx={{ marginBottom: "1rem" }}>
                          <Field
                            name="value"
                            type="input"
                            label="Url"
                            id="value"
                            as={TextField}
                            fullWidth
                            size="small"
                          />
                          <ErrorMessage name="value" component={MzErrorText} />
                        </Box>
                        <Box display="flex" flexDirection="row" gap="0.5rem">
                          <Button variant="primary" type="submit">
                            Ajouter
                          </Button>
                          <Button onClick={handleCloseAddLink} variant="third">
                            Annuler
                          </Button>
                        </Box>
                      </Form>
                    )}
                  </Formik>
                  {/* end form add link */}
                </Box>
              </Modal>

              {/* modal update link */}
              <Modal open={openUpdate} onClose={handleCloseUpdate}>
                <Box sx={ModalStyle}>
                  <Typography fontSize="1.5rem" fontWeight="600" mb="2rem">
                    Mettre à jour un lien
                  </Typography>
                  {/* form update link */}
                  <Formik
                    initialValues={{
                      link_id: selectedLinkToDelete?.link.id ?? "",
                      value: selectedLinkToDelete?.value ?? "",
                      title: selectedLinkToDelete?.title ?? "",
                    }}
                    validationSchema={validationSchemaAddLink}
                  >
                    {({ values, setFieldValue, errors }) => (
                      <Form>
                        <Box className="field" sx={{ marginBottom: "1rem" }}>
                          <Field
                            fullWidth
                            name="link_id"
                            component={Autocomplete}
                            defaultValue={{
                              id: selectedLinkToDelete?.link.id ?? "",
                              slug: selectedLinkToDelete?.link.slug,
                              title: selectedLinkToDelete?.link.title,
                              url_logo: selectedLinkToDelete?.link.url_logo,
                            }}
                            options={possibleLinksList}
                            isOptionEqualToValue={(option, value) =>
                              option.id === value.id
                            }
                            getOptionLabel={(pl) =>
                              pl.title !== "Site Web" ? pl.title : "Autre"
                            }
                            onChange={(event, value) => {
                              if (value === null) {
                                value = { link_id: "" };
                                setFieldValue("title", "");
                                setFieldValue("link_id", "");
                              }

                              setFieldValue("link_id", value ? value.id : "");
                              setFieldValue("title", value ? value.title : "");

                              if (value.slug === "other") {
                                setAllowOtherField(true);
                                setFieldValue("title", value ? "Site web" : "");
                              } else {
                                setAllowOtherField(false);
                              }
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                name="autocomplete"
                                label="Lien vers"
                              />
                            )}
                            size="small"
                          />
                          <ErrorMessage
                            name="link_id"
                            component={MzErrorText}
                          />
                        </Box>

                        {allowOtherField ? (
                          <Box className="field" sx={{ marginBottom: "1rem" }}>
                            <Field
                              name="title"
                              type="input"
                              label="Titre"
                              id="title"
                              as={TextField}
                              fullWidth
                              size="small"
                            />
                            <ErrorMessage
                              name="title"
                              component={MzErrorText}
                            />
                          </Box>
                        ) : (
                          ""
                        )}

                        <Box className="field" sx={{ marginBottom: "1rem" }}>
                          <Field
                            name="value"
                            type="input"
                            label="Url"
                            id="value"
                            as={TextField}
                            fullWidth
                            size="small"
                          />
                          <ErrorMessage name="value" component={MzErrorText} />
                        </Box>

                        <Box display="flex" flexDirection="row" gap="0.5rem">
                          <Button
                            variant="primary"
                            onClick={() => {
                              //console.log("SELECTED LINK TO UPDATE:", selectedLinkToDelete?.id);
                              //console.log("ERRORS :::", errors);
                              if (isObjEmpty(errors)) {
                                updateLink(values, selectedLinkToDelete?.id);
                              }
                            }}
                          >
                            Mettre à jour
                          </Button>
                          <Button onClick={handleCloseUpdate} variant="third">
                            Annuler
                          </Button>
                        </Box>
                      </Form>
                    )}
                  </Formik>
                  {/* end form update link */}
                </Box>
              </Modal>
              {/* end modal update link  */}
            </Box>
          )}

          {inDocuments && (
            <Box
              width="100%"
              sx={{
                backgroundColor: "#F9F6FF",
                borderRadius: "2rem",
                padding: "0.5rem",
              }}
            >
              {docs?.map((d) => (
                <Box
                  key={d.id}
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <IconLink title={d.title} value={d.url_file} />
                  <Box>
                    <MyIconButton
                      onClick={() => {
                        setSelectedDocToDelete(d);
                        handleOpenDeleteDoc();
                      }}
                      sx={{ padding: "1rem" }}
                      name="delete"
                    />
                  </Box>
                </Box>
              ))}
              <IconButton
                sx={{
                  margin: "0.5rem 1rem",
                  bgcolor: `${colors.main_purple}`,
                  "&:hover": { bgcolor: `${colors.main_purple_hover}` },
                }}
                onClick={() => {
                  handleOpenAddDoc();
                }}
              >
                <AddRoundedIcon htmlColor="white" />
              </IconButton>
              {/* Modal add DOC */}
              <Modal open={openAddDoc} onClose={handleCloseAddDoc}>
                <Box sx={ModalStyle}>
                  <Typography fontSize="1.5rem" fontWeight="600" mb="2rem">
                    Ajouter un document
                  </Typography>
                  {/* form add doc */}
                  <Formik
                    initialValues={initialValuesAddDoc}
                    validationSchema={validationSchemaAddDoc}
                    onSubmit={addDoc}
                  >
                    {({ values, setFieldValue, errors }) => (
                      <Form>
                        {/* doc title */}
                        <Box className="field" sx={{ marginBottom: "1rem" }}>
                          <Field
                            name="title"
                            type="input"
                            label="Titre"
                            id="title"
                            as={TextField}
                            fullWidth
                            size="small"
                          />
                          <ErrorMessage name="title" component={MzErrorText} />
                        </Box>
                        {loadingDoc === true && (
                          <Stack
                            margin="1rem 0rem"
                            gap="0.5rem"
                            direction="row"
                            sx={{
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <Typography color={colors.main_purple}>
                              {progress ? progress + "%" : null}
                            </Typography>
                            <CircularProgress />
                          </Stack>
                        )}

                        {/* doc file */}
                        <Box
                          display="flex"
                          flexDirection="column"
                          sx={{ marginBottom: "1rem" }}
                        >
                          <Button
                            variant="upload"
                            sx={{
                              height: "200px",
                              display: "flex",
                              flexDirection: "column",
                            }}
                            component="label"
                          >
                            <img
                              src={FileIcon}
                              width="50px"
                              alt=""
                              style={{ marginBottom: "0.5rem" }}
                            />
                            <input
                              style={{
                                opacity: "0%",
                                width: "100%",
                                height: "100%",
                                position: "absolute",
                              }}
                              //style={{ opacity: "100%", width: "0px", height: "0px" }}
                              id="document"
                              name="document"
                              type="file"
                              // accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                              onChange={(event) => {
                                const doc = event.target.files[0];
                                setFieldValue(
                                  "document",
                                  event.target.files[0]
                                );
                                setDocToAddName(doc.name);
                              }}
                            />
                            <Typography
                              sx={{ fontSize: "1rem", fontWeight: "400" }}
                            >
                              Déposer votre fichier
                            </Typography>
                          </Button>
                        </Box>
                        <MzErrorText>{errors.document}</MzErrorText>
                        {docToAddName && (
                          <Box>
                            <Typography
                              sx={{
                                margin: "1rem 0rem",
                                color: `${colors.main_purple}`,
                                fontWeight: "500",
                                textDecoration: "underline",
                              }}
                              fontSize="0.875rem"
                            >
                              {docToAddName}
                            </Typography>
                          </Box>
                        )}

                        {/* confirm add doc */}
                        <Box display="flex" flexDirection="row" gap="0.5rem">
                          <Button variant="primary" type="submit">
                            Ajouter
                          </Button>
                          <Button onClick={handleCloseAddDoc} variant="third">
                            Annuler
                          </Button>
                        </Box>
                        {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
                      </Form>
                    )}
                  </Formik>
                  {/* end form add DOC */}
                </Box>
              </Modal>
              <ConfirmationModal
                title="Supprimer un document"
                message="Êtes-vous sûr de bien vouloir supprimer ce document ?"
                acceptLabel="oui"
                refuseLabel="non"
                open={openDeleteDoc}
                onClose={handleCloseDeleteDoc}
                acceptMethod={deleteDoc}
                acceptMethodParams={selectedDocToDelete?.id}
              />
            </Box>
          )}
        </Box>
        {/* End links docs section */}
      </Box>
    </Box>
  );
}

export default CarteAbonneDetails;
