import React from "react";
import { useState, useEffect,useRef } from "react";
import { Box, TextField, Button, Typography, Avatar, IconButton, Modal } from "@mui/material";
import { ToastContainer } from "react-toastify";
import userIcon from "../../imgs/user.png";
import { colors } from "../../theme/Colors";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import Api from "../../api/Api";
import { Urls } from "../../api/Urls";
import Cropper from "react-easy-crop";
import * as Yup from "yup";
import MzErrorText from "../../components/ui/MzErrorText";
import getCroppedImg from "../../utils/cropImage";
import { CircularProgress } from "@mui/material";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useAuthContext } from "../../hooks/useAuthContext";
import MainTitle from "../../components/ui/MainTitle";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";


function Entreprise() {
  const navigate = useNavigate();
  const { user } = useAuthContext();
  const access_token = user.access_token;
  const id = user.user.id;
  const idEntreprise = user?.user?.enterprise?.id;

  const [data, setData] = useState({});
  const [image, setImage] = useState();
  const [edit,setEdit] = useState(false)
  const formikRef = useRef();
  const imageProfile = useRef();
  const [progress, setProgress] = useState(0);
  const [loading,setLoading] = useState(false)
  const [onUploadImage, setOnUploadImage] = useState(false);
  const FILE_SIZE = 5000 * 1024;
  const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/png", "image/svg+xml", "image/webp"];
  //validation schema profile picture
  const validationSchemaProfilePicture = Yup.object({
    logo: Yup.mixed()
      .required("Veuillez télécharger un fichier")
      .test("fileSize", "Fichier volumineux", (value) => value && value.size <= FILE_SIZE)
      .test("fileFormat", "Format du fichier invalide", (value) => value && SUPPORTED_FORMATS.includes(value.type)),
    ImageWidth: Yup.number().min(191, "Largeur minimale de 192 pixels"),
    ImageHeight: Yup.number().min(191, "Hauteur minimale de 192 pixels"),
  });
  var configsImage = {
    headers: {
      "Content-type": "multipart/form-data",
      authorization: `Bearer ${access_token}`,
    },
    onUploadProgress: function (progressEvent) {
      var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      setProgress(percentCompleted);
    },
  };
  const [format, setFormat] = useState("");
  const [onImage, setOnImage] = useState(false);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState();
  const [change, setChange] = useState(false);
  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };
  const onCrop = async () => {
    const croppedImage = await getCroppedImg(image, croppedAreaPixels, format);
    setImage(URL.createObjectURL(croppedImage));
    var file = new File([croppedImage], `${Math.floor(Math.random() * 10000000000000000)}.${format.split("/")[1]}`, { lastModified: 1534584790000, type: [`${format}`] });
    if (file && croppedImage) {
      setOnImage(false);
      formikRef.current.values.logo = file;
      file = null;
    }
  };
  const styleCropper = {
    containerStyle: {
      positon: "absolute",
      top: "20px",
      margin: "0 auto",
      height: "60%",
      width: "60%",
    },
  };
  
  //initial values
  const initialValues = {
  social_reason: data?.enterprise?.social_reason || "",
  commercial_name: data?.enterprise?.commercial_name || "",
  commercial_register: data?.enterprise?.commercial_register || "",
  nif: data?.enterprise?.nif || "",
  country_name: data?.enterprise?.country_name || "",
  city_name: data?.enterprise?.city_name || "",
  postal_code: data?.enterprise?.postal_code || "",
  address: data?.enterprise?.address || "",
  phone_number: data?.enterprise?.phone_number || "",
  email: data?.enterprise?.email || "",
  fix: data?.enterprise?.fix || "",
  };

  var configs = {
    headers: {
      "Content-type": "multipart/form-data",
      authorization: `Bearer ${access_token}`,
    },
    onUploadProgress: function (progressEvent) {
      var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      setProgress(percentCompleted);
    },
  };

  const getCompany = ()=>{
    //console.log(response.data.user);
    Api.get(Urls.GET_ENTREPRISE_DETAILS + `${idEntreprise}/details`, {
     headers: {
       "Content-type": "application/json",
       accept: "application/json",
       authorization: `Bearer ${access_token}`,
     },
   })
     .then((response) => {
       setData(response.data);
       //console.log(response.data);
       setImage(response?.data?.enterprise?.url_logo);
     })
     .catch((err) => {
       if (err.response.status === 401) {
         localStorage.removeItem("user");
         navigate("/login", { replace: true });
         navigate(0, { replace: true });
       }
       //console.log(error);
       if (err.response.status === 403) toast.error("Une erreur est survenue");
       else toast.error(err.response.data.message);
     });
 }

  const onSubmitUpdateEntrepriseLogo = (values) => {
    Api.post(
      Urls.UPDATE_ENTREPRISE_LOGO + `${data?.enterprise?.id}/logo`,
      {
        logo: values.logo,
      },
      configs
    )
      .then((response) => {
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.removeItem("user");
          navigate("/login", { replace: true });
          navigate(0, { replace: true });
        }
        // console.log("RESPONSE", error.response.data.message);
        if (error?.response?.status === 403) {
          toast.error("Une erreur est survenue");
        } else {
          toast.error(error?.response?.data?.message);
        }
      });
  };

  const onSubmitCreateEntreprise = (values) => {

    if(values.logo){
      onSubmitUpdateEntrepriseLogo(values)
    }
    console.log("values: ", values);
    Api.post(
      Urls.UPDATE_ENTREPRISE_INFOS_ENTREPRISE + `${data.enterprise.id}`,
        {
          social_reason: values.social_reason,
          commercial_name: values.commercial_name,
          commercial_register: values.commercial_register,
          nif: values.nif,
          country_name: values.country_name,
          city_name: values.city_name,
          postal_code: values.postal_code,
          address: values.address,
          phone_number: values.phone_number,
          email: values.email,
          fix: values.fix,
          _method: 'PATCH',
      },
      configs
    )
      .then((response) => {
        // console.log("RESPONSE", response);

        if (response.status === 200) {
          // console.log(response);
          toast.success("Entreprise modifiée avec succès");
          getCompany()
          setEdit(false)
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.removeItem("user");
          navigate("/login", { replace: true });
          navigate(0, { replace: true });
        }
        // console.log("RESPONSE", error.response.data.message);
        if (error?.response?.status === 403) {
          toast.error("Une erreur est survenue");
        } else {
          toast.error(error?.response?.data?.message);
        }
      });
      setLoading(false)
  };

  //initial values

  //validation schema
  const validationSchemaPassword = Yup.object({
    ancien_motdepasse: Yup.string().required("Ce champ est obligatoire"),
    nouveau_motdepasse: Yup.string()
      .required("Ce champ est obligatoire")
      .notOneOf([Yup.ref("ancien_motdepasse"), null], "Le nouveau mot de passe doit être différent de l'ancient"),
    confirmer_motdepasse: Yup.string()
      .required("Ce champs est obligatoire")
      .oneOf([Yup.ref("nouveau_motdepasse"), null], "Le mot de passe ne correspond pas"),
  });

  //validation schema
  const validationSchema = Yup.object({
  social_reason: Yup.string().required("Ce champ est obligatoire"),
  commercial_name: Yup.string().required("Ce champ est obligatoire"),
  commercial_register: Yup.string().required("Ce champ est obligatoire"),
  nif: Yup.string().required("Ce champ est obligatoire"),
  country_name: Yup.string().required("Ce champ est obligatoire"),
  city_name: Yup.string().required("Ce champ est obligatoire"),
  postal_code: Yup.string().required("Ce champ est obligatoire"),
  address: Yup.string().required("Ce champ est obligatoire"),
  phone_number: Yup.number().required("Ce champ est obligatoire"),
  email: Yup.string().required("Ce champ est obligatoire").email('Email non Valide'),
  fix: Yup.number(),
  });


  useEffect(() => {
   getCompany()
  }, [access_token, idEntreprise, navigate]);

  const updateMotDePasse = (values) => {
    Api.patch(
      Urls.UPDATE_USER_PASSWORD + `${id}/password`,
      {
        old_password: values.ancien_motdepasse,
        new_password: values.nouveau_motdepasse,
      },
      {
        headers: { "Content-Type": "application/json", authorization: `Bearer ${access_token}` },
      }
    )
      .then((res) => {
        toast.success("Mot de passe modifié");
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        if (err.response.status === 401) {
          localStorage.removeItem("user");
          navigate("/login", { replace: true });
          navigate(0, { replace: true });
        }
        if (err.response.status === 403) toast.error("Une erreur est survenue");
        else toast.error(err.response.data.message);
        if (err.response.status === 401) {
          localStorage.removeItem("user");
          navigate("/login", { replace: true });
          navigate(0, { replace: true });
        }
      });
  };


  return (
    <Box maxWidth="800px">
      <ToastContainer style={{ top: "5rem" }} position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="dark" />
      {/* Header Section */}
      {edit !== true && 
      <Box>
        <Avatar
          sx={{
            position: "relative",
            borderRadius: "20px",
            width: "12rem",
            height: "12rem",
            border: "1px solid #C9C5D2",
            marginBottom: "1rem",
          }}
          variant="rounded"
        >
          {image ? <img src={image} alt="profile pic" style={{ width: "12rem", height: "12rem", objectFit: "fill", objectPosition: "50% 50%" }} /> : <img src={userIcon} alt="profile pic" style={{ width: "12rem", height: "12rem", objectFit: "fill", objectPosition: "50% 50%" }} />}
        </Avatar>
      </Box> }

      {edit === true ?

      <Formik
      innerRef={formikRef}
      enableReinitialize={true}
      initialValues={{
        logo: data?.entreprise?.url_logo ?? "",
        ImageHeight: 192,
        ImageWidth: 192,
      }}
      validationSchema={validationSchemaProfilePicture}
      onSubmit={(values) => {
        onSubmitUpdateEntrepriseLogo(values, access_token);
      }}
      >
        {({values,setFieldValue,errors})=> (
            <Form>
              <Box display="flex" flexDirection="column">
           <Avatar
             sx={{
               position: "relative",
               borderRadius: "20px",
               width: "12rem",
               height: "12rem",
               border: "1px solid #C9C5D2",
               marginBottom: "1rem",
             }}
             variant="rounded"
           >
             {image ? (
               <img ref={imageProfile} src={image} alt="profile pic" style={{ height: "12rem", width: "12rem", objectFit: "fill", objectPosition: "50% 50%" }} />
             ) : data?.url_profile_picture ? (
               <img ref={imageProfile} alt="profile pic" src={data?.url_profile_picture} style={{ height: "12rem", width: "12rem", objectFit: "fill", objectPosition: "50% 50%" }} />
             ) : (
               <img src={userIcon} alt="profile pic" style={{ height: "12rem", width: "12rem", objectFit: "fill", objectPosition: "50% 50%" }} />
             )}
             <IconButton
               sx={{
                 zIndex: "33",
                 position: "absolute",
                 right: "0.5rem",
                 bottom: "0.5rem",
                 bgcolor: `${colors.main_purple}`,
                 "&:hover": { bgcolor: `${colors.main_purple_hover}` },
               }}
               component="label"
             >
               <input
                 hidden
                 id="logo"
                 name="logo"
                 type="file"
                 onChange={(event) => {
                   setFieldValue("logo", event.target.files[0] ? event.target.files[0] : null);
                   if (event.target.files[0]) {
                     setOnImage(true);
                     setImage(URL.createObjectURL(event.target.files[0]));
                     setFormat(event.target.files[0].type);
                   }
                 }}
               />
               <AddRoundedIcon htmlColor="white" />
             </IconButton>
           </Avatar>
           <Modal
             sx={{ backgroundColor: "#3f3f3f" }}
             open={onImage}
             onClose={() => {
               setImage(null);
               formikRef.current.values.logo = "";
               setOnImage(false);
             }}
           >
             <Box height="100%" width="100%" margin="0 auto">
               <Box sx={{ height: "80%", width: "80%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                 <Box height="100%" width="100%" display="flex" flexDirection="column">
                   <Cropper style={styleCropper} image={image} crop={crop} zoom={zoom} aspect={1 / 1} onCropChange={setCrop} onCropComplete={onCropComplete} onZoomChange={setZoom} />
                 </Box>
               </Box>
               <Box position="absolute" bottom="5%" left="0" right="0" gap="1rem" display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                 <MzErrorText>{errors.logo ? errors.logo : null}</MzErrorText>
                 <Box width="fit-content" display="flex" flexDirection="row" gap="1rem" alignItems="center">
                    <Button disabled={errors?.logo ? true : false} onClick={onCrop} variant="primary">
                     Confirmer
                   </Button>
                   <Button
                     variant="primary"
                     onClick={() => {
                       setImage(data?.enterprise?.url_logo);
                       formikRef.current.values.logo = "";
                       setOnImage(false);
                     }}
                   >
                     Annuler
                   </Button>
                 </Box>
                 <Box display="flex" gap="0.5rem" sx={{ flexDirection: { xs: "column", sm: "row", lg: "row", md: "row" }, alignItems: "center" }}>
                   <Typography color="white">Niveau de zoom : </Typography>
                   <input type="range" min={1} max={3} step={0.1} value={zoom} onInput={(e) => setZoom(e.target.value)} />
                 </Box>
               </Box>
             </Box>
           </Modal>
           <Box>
             {errors.logo ? <MzErrorText>{errors.logo}</MzErrorText> : null}
             {values.ImageHeight < 191 ? <MzErrorText>Hauteur minimale de 192 pixels</MzErrorText> : null}
             {values.ImageWidth < 191 ? <MzErrorText>Largeur minimale de 192 pixels</MzErrorText> : null}
           </Box>{" "}
           <Box display="flex" flexDirection="column" alignItems="start" gap="0.5rem" sx={{ margin: "0.5rem 0rem 1rem 0rem" }}>
             <Box>
               {onUploadImage && (
                 <Box display="flex" alignItems="center" gap="1rem">
                   <CircularProgress size={30} />
                   <Typography width="20px" color={colors.main_purple}>
                     {progress ? progress + "%" : null}
                   </Typography>
                 </Box>
               )}
             </Box>
           </Box>
            </Box> 
            </Form>
        )}
      </Formik>

       : null
       }
      <Box>
      <Box
        display={"flex"}
        flexDirection={"column"}
        alignItems={"start"}
        gap={".3rem"}
        mb={2}
      >
        <MainTitle
          title="Informations de l'entreprise"
          icon="entreprise_purple"
        />
      {edit !== true ? <Button variant="primary" sx={{'fontWeight':'500'}} onClick={()=> setEdit(true)}>Edit</Button> : null}
      </Box>        
      </Box>

     { edit !== true ? <Box sx={{ maxWidth: { xs: "500px" }, width: { md: "500px" } }}>
        <Box width="100%" sx={{ backgroundColor: "#F9F6FF", borderRadius: "2rem", padding: "0.5rem" }}>
          <Box sx={{ whiteSpace: "pre-line", wordBreak: "break-word", flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" }, alignItems: { xs: "start", sm: "start", md: "center", lg: "center" } }} display="flex" gap="1rem" margin="1rem">
            <Typography fontWeight={500}>Raison sociale</Typography>
            <Typography variant="secondary">{data?.enterprise?.social_reason ? data?.enterprise?.social_reason : ""}</Typography>
          </Box>
          <Box sx={{ whiteSpace: "pre-line", wordBreak: "break-word", flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" }, alignItems: { xs: "start", sm: "start", md: "center", lg: "center" } }} display="flex" gap="1rem" margin="0rem 1rem 1rem 1rem">
            <Typography fontWeight={500}>Nom commercial</Typography>
            <Typography variant="secondary">{data?.enterprise?.commercial_name ? data?.enterprise?.commercial_name : ""}</Typography>
          </Box>
          <Box sx={{ whiteSpace: "pre-line", wordBreak: "break-word", flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" }, alignItems: { xs: "start", sm: "start", md: "center", lg: "center" } }} display="flex" gap="1rem" margin="0rem 1rem 1rem 1rem">
            <Typography fontWeight={500}>Numéro de registre de commerce </Typography>
            <Typography variant="secondary">{data?.enterprise?.commercial_register ? data?.enterprise?.commercial_register : ""}</Typography>
          </Box>
          <Box sx={{ whiteSpace: "pre-line", wordBreak: "break-word", flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" }, alignItems: { xs: "start", sm: "start", md: "center", lg: "center" } }} display="flex" gap="1rem" margin="0rem 1rem 1rem 1rem">
            <Typography fontWeight={500}>NIF</Typography>
            <Typography variant="secondary">{data?.enterprise?.nif ? data?.enterprise?.nif : ""}</Typography>
          </Box>
          <Box sx={{ whiteSpace: "pre-line", wordBreak: "break-word", flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" }, alignItems: { xs: "start", sm: "start", md: "center", lg: "center" } }} display="flex" gap="1rem" margin="1rem 1rem 1rem 1rem">
            <Typography fontWeight={500}>Adresse du siège social</Typography>
            <Typography variant="secondary">{data?.enterprise?.address ? data?.enterprise?.address : ""}</Typography>
          </Box>
          <Box sx={{ whiteSpace: "pre-line", wordBreak: "break-word", flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" }, alignItems: { xs: "start", sm: "start", md: "center", lg: "center" } }} display="flex" gap="1rem" margin="1rem 1rem 1rem 1rem">
            <Typography fontWeight={500}>Pays</Typography>
            <Typography variant="secondary">{data?.enterprise?.country_name ? data?.enterprise?.country_name : ""}</Typography>
          </Box>
          <Box sx={{ whiteSpace: "pre-line", wordBreak: "break-word", flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" }, alignItems: { xs: "start", sm: "start", md: "center", lg: "center" } }} display="flex" gap="1rem" margin="1rem 1rem 1rem 1rem">
            <Typography fontWeight={500}>Code postal</Typography>
            <Typography variant="secondary">{data?.enterprise?.postal_code ? data?.enterprise?.postal_code : ""}</Typography>
          </Box>
          <Box sx={{ whiteSpace: "pre-line", wordBreak: "break-word", flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" }, alignItems: { xs: "start", sm: "start", md: "center", lg: "center" } }} display="flex" gap="1rem" margin="1rem 1rem 1rem 1rem">
            <Typography fontWeight={500}>Ville</Typography>
            <Typography variant="secondary">{data?.enterprise?.city_name ? data?.enterprise?.city_name : ""}</Typography>
          </Box>
          <Box sx={{ whiteSpace: "pre-line", wordBreak: "break-word", flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" }, alignItems: { xs: "start", sm: "start", md: "center", lg: "center" } }} display="flex" gap="1rem" margin="1rem 1rem 1rem 1rem">
            <Typography fontWeight={500}>Email</Typography>
            <Typography variant="secondary">{data?.enterprise?.email ? data?.enterprise?.email : ""}</Typography>
          </Box>
          <Box sx={{ whiteSpace: "pre-line", wordBreak: "break-word", flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" }, alignItems: { xs: "start", sm: "start", md: "center", lg: "center" } }} display="flex" gap="1rem" margin="1rem 1rem 1rem 1rem">
            <Typography fontWeight={500}>Téléphone</Typography>
            <Typography variant="secondary">{data?.enterprise?.phone_number ? data?.enterprise?.phone_number : ""}</Typography>
          </Box>
          <Box sx={{ whiteSpace: "pre-line", wordBreak: "break-word", flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" }, alignItems: { xs: "start", sm: "start", md: "center", lg: "center" } }} display="flex" gap="1rem" margin="1rem 1rem 1rem 1rem">
            <Typography fontWeight={500}>Téléphone Fix</Typography>
            <Typography variant="secondary">{data?.enterprise?.fix ? data?.enterprise?.fix : ""}</Typography>
          </Box>
        </Box>
      </Box>


      :

      <Box>
        <Box>

        {/* FORMULAIRE  */}

        <Formik
          innerRef={formikRef}
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            setLoading(true)
            onSubmitCreateEntreprise(values, access_token);
          }}
        >
          {({ values, setFieldValue, errors }) => (
            <Form>
              <Box
                display="flex"
                sx={{
                  flexDirection: { lg: "row", xs: "column" },
                  gap: "2rem",
                }}
              >
                {/* SECTION ENTREPRISE INFOS */}
                <Box> 
                  <Box
                    display="grid"
                    maxWidth="1000px"
                    sx={{ gridTemplateColumns:'repeat(2,1fr)'}}
                    marginBottom="1rem"
                  >
                    <Box
                      marginRight="1rem"
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        marginBottom: "1.3rem",
                      }}
                    >
                      <Field
                        name="social_reason"
                        as={TextField}
                        variant="outlined"
                        label="Raison sociale*"
                        size="small"
                      />
                      <ErrorMessage
                        name="social_reason"
                        component={MzErrorText}
                      />
                    </Box>

                    <Box
                      marginRight="1rem"
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        marginBottom: "1.3rem",
                      }}
                    >
                      <Field
                        name="commercial_name"
                        as={TextField}
                        variant="outlined"
                        label="Nom commercial*"
                        size="small"
                      />
                      <ErrorMessage
                        name="commercial_name"
                        component={MzErrorText}
                      />
                    </Box>

                    <Box
                      marginRight="1rem"
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        marginBottom: "1.3rem",
                      }}
                    >
                      <Field
                        name="commercial_register"
                        as={TextField}
                        variant="outlined"
                        label="Registre commercial*"
                        size="small"
                      />
                      <ErrorMessage
                        name="commercial_register"
                        component={MzErrorText}
                      />
                    </Box>

                    <Box
                      marginRight="1rem"
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        marginBottom: "1.3rem",
                      }}
                    >
                      <Field
                        name="nif"
                        as={TextField}
                        variant="outlined"
                        label="NIF*"
                        size="small"
                      />
                      <ErrorMessage
                        name="nif"
                        component={MzErrorText}
                      />
                    </Box>

                    <Box
                      marginRight="1rem"
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        marginBottom: "1.3rem",
                      }}
                    >
                      <Field
                        name="country_name"
                        as={TextField}
                        variant="outlined"
                        label="Pays*"
                        size="small"
                      />
                      <ErrorMessage
                        name="country_name"
                        component={MzErrorText}
                      />
                    </Box>

                    <Box
                      marginRight="1rem"
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        marginBottom: "1.3rem",
                      }}
                    >
                      <Field
                        name="city_name"
                        as={TextField}
                        variant="outlined"
                        label="Ville*"
                        size="small"
                      />
                      <ErrorMessage
                        name="city_name"
                        component={MzErrorText}
                      />
                    </Box>

                    <Box
                      marginRight="1rem"
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        marginBottom: "1.3rem",
                      }}
                    >
                      <Field
                        name="postal_code"
                        as={TextField}
                        variant="outlined"
                        label="Code Postal*"
                        size="small"
                      />
                      <ErrorMessage
                        name="postal_code"
                        component={MzErrorText}
                      />
                    </Box>

                    <Box
                      marginRight="1rem"
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        marginBottom: "1.3rem",
                      }}
                    >
                      <Field
                        name="email"
                        as={TextField}
                        variant="outlined"
                        label="Email*"
                        size="small"
                      />
                      <ErrorMessage
                        name="email"
                        component={MzErrorText}
                      />
                    </Box>

                    <Box
                      marginRight="1rem"
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        marginBottom: "1.3rem",
                      }}
                    >
                      <Field
                        name="phone_number"
                        as={TextField}
                        variant="outlined"
                        label="Téléphone*"
                        size="small"
                      />
                      <ErrorMessage
                        name="phone_number"
                        component={MzErrorText}
                      />
                    </Box>

                    <Box
                      marginRight="1rem"
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        marginBottom: "1.3rem",
                      }}
                    >
                      <Field
                        name="fix"
                        as={TextField}
                        variant="outlined"
                        label="Téléphone Fix*"
                        size="small"
                      />
                      <ErrorMessage
                        name="fix"
                        component={MzErrorText}
                      />
                    </Box>

                    <Box
                      marginRight="1rem"
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        marginBottom: "1.3rem",
                        gridColumn: 'span 2'
                      }}
                    >
                      <Field
                        name="address"
                        as={TextField}
                        variant="outlined"
                        label="Addresse*"
                        size="small"
                      />
                      <ErrorMessage
                        name="address"
                        component={MzErrorText}
                      />
                    </Box>

                  </Box>
                </Box>

              </Box>
              <Box display={"flex"} alignItems={"center"} gap={'1rem'}>
               {loading === true ? <CircularProgress/> : <Button variant="primary" type="submit">Modifier Enterprise</Button>}
                <Button variant="secondary" onClick={()=> {
                  setEdit(false)
                  setLoading(false)
                  setImage(data?.enterprise?.url_logo)
                }}>Annuler</Button>
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
      </Box>
      
      }


      <Box>
        <Typography sx={{ margin: "2rem 0rem 1rem 0rem", fontWeight: "500" }}>Changer mot de passe du compte</Typography>
      </Box>

      <Box>
        <Formik
          enableReinitialize={true}
          initialValues={{
            ancien_motdepasse: "",
            nouveau_motdepasse: "",
            confirmer_motdepasse: "",
          }}
          validationSchema={validationSchemaPassword}
          onSubmit={(values) => {
            //console.log(values);
            updateMotDePasse(values);
            //onSubmitUpdateEntreprise(values, access_token);
          }}
        >
          {({ values, setFieldValue }) => (
            <Form>
              {/* SECTION ENTREPRISE INFOS */}
              <Box>
                <Box marginBottom="1rem">
                  <Box
                    marginRight="1rem"
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      marginBottom: "1.3rem",
                    }}
                  >
                    <Field type="password" name="ancien_motdepasse" sx={{ maxWidth: "500px" }} as={TextField} variant="outlined" label="Ancien mot de passe" size="small" />
                    <ErrorMessage name="ancien_motdepasse" component={MzErrorText} />
                  </Box>
                  <Box
                    marginRight="1rem"
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      marginBottom: "1.3rem",
                    }}
                  >
                    <Field type="password" name="nouveau_motdepasse" as={TextField} sx={{ maxWidth: "500px" }} variant="outlined" label="Nouveau mot de passe*" size="small" />
                    <ErrorMessage name="nouveau_motdepasse" component={MzErrorText} />
                  </Box>
                  <Box
                    marginRight="1rem"
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      marginBottom: "1.3rem",
                    }}
                  >
                    <Field type="password" name="confirmer_motdepasse" sx={{ maxWidth: "500px" }} as={TextField} variant="outlined" label="Confirmer nouveau mot de passe*" size="small" />
                    <ErrorMessage name="confirmer_motdepasse" component={MzErrorText} />
                  </Box>
                </Box>
              </Box>
              <Box>
                <Button type="submit" variant="primary">
                  Changer mot de passe
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </Box>
  );
}

export default Entreprise;
