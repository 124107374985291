import React, { useEffect, useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import "./style/SharedLayout.css";
import Api from "../../api/Api";
import bdigitall from "../../imgs/BDIGITALL.svg";
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import InsertLinkOutlinedIcon from '@mui/icons-material/InsertLinkOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import ContactModal from '../../components/ui/ContactModal'
import { toast } from "react-toastify";
import { AccountCircle, Category, Circle, ContactsOutlined, DownloadSharp, Group, VisibilityOutlined } from "@mui/icons-material";
import { Urls } from "../../api/Urls";
import { Avatar, Box, Button, Tab, Tabs } from "@mui/material";
import IconLink from "../../components/ui/IconLink_bdisplay";
import DefaultProfilePic from "../../imgs/user.png";

function Bdisplay() {

  const [services, setServices] = useState([]);
  const [page, setPage] = useState([]);
  const [i, setI] = useState(false);
  const location = useLocation();
  const [display, setDisplay] = useState("")
  const [clickedButton, setClickedButton] = useState("");
  const [serviceToConsult, setServiceToConsult] = useState(null);
  const [currentServiceUrl, setCurrentServiceUrl] = useState(null);
  const [currentServiceUrlFile, setCurrentServiceUrlFile] = useState(null);
  const [canSaveContacts,setCanSaveContacts] = useState(null)
  const [canSaveContactsOnConsult,setCanSaveContactsOnConsult] = useState(null)
  const [possibleLinksList, setPossibleLinksList] = useState();
  const [links, setLinks] = useState([]);
  const navigate = useNavigate();




  async function getServices() {
    try {
      const pathname = location.pathname.split("/");
      const search = location.search.split("=");
      const pageId = pathname[2];
      setDisplay(search[1])
      const response = await Api.get(`/bdisplay/${pageId}`, {
        headers: {
          "Content-Type": "application/json",
          "Accept": "application/json",
          // authorization: `Bearer ${access_token}`,
        },
      });

      if(response.status === 200){
        setServices(response.data.services);
        setPage(response.data.page ?? {});
        setCanSaveContacts(response.data?.page?.can_save_contacts)
        setCanSaveContactsOnConsult(response.data?.page?.can_save_contacts_on_consultation)
        setLinks(response.data.page.links ?? [])
        setUsers(response.data.page.users ?? [])


      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    if (!i) {
      getServices();
      setI(true);
    }
    Api.get(Urls.GET_POSSIBLE_LINKS, {
      headers: {
        "Content-type": "application/json",
        accept: "application/json",
      },
    })
      .then((response) => {
        setPossibleLinksList(response.data.links);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.removeItem("user");
          navigate("/login", { replace: true });
          navigate(0, { replace: true });
        }
        if (error?.response?.status === 403) {
          toast.error("Une erreur est survenue");
        } else {
          toast.error(error?.response?.data?.message);
        }
      });
  }, []);
  const handleDownload = async (url) => {
    try {
      const response = await fetch(url);
      const blob = await response.blob();
      const href = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = href;
      link.download = 'download';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  const handleAction = (action) =>{
    setClickedButton(action)
  }

  const handleSendStats = async (id) =>{
    const data = {
      service_id : id,
      type_action: clickedButton === 'CONSULT_URL' ? 'CONSULT_URL' : 'DOWNLOAD_BROCHURE'
    }
    try {
      await Api.post(`/bdisplay/services/store-consultation`,
    data,
    {
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
      },
    });
  } catch (error) {
    console.log(error);
  }
  }
  
  const handleSuccess = (url, file) => {
    setCurrentServiceUrl(url);
    setCurrentServiceUrlFile(file);


    if(clickedButton === 'CONSULT_URL' && canSaveContactsOnConsult === true){
        window.open(url, "_blank");
        setClickedButton('');
    }
    else if (clickedButton === 'DOWNLOAD_BROCHURE' && canSaveContacts === true) {
        setTimeout(()=>{
            handleDownload(file, 'brochure.pdf')
        },1000)
        setClickedButton('');
    }
}

  const handleError = (error) =>{
    console.log(error)
  }

  const getLogoUrl = (title) => {
    const link = possibleLinksList?.find((link) => link.title === title);
    return link ? link.url_logo : "";
  };
  

  useEffect(() => {
    if (clickedButton && serviceToConsult && services.length > 0) {
        const currentService = services.find(service => service.id === serviceToConsult);
        if (currentService) {
            setCurrentServiceUrl(currentService.url);
            setCurrentServiceUrlFile(currentService.url_file);
        }
        if(canSaveContacts === true){
          return;
        }
    }
}, [clickedButton, serviceToConsult, services]);

    // NEW : page contacts

  const [value, setValue] = useState('one');
  const [users, setUsers] = useState([])
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
        minHeight: "100vh"
      }}
    >
      {!page.title ? <h3 className="text-2xl font-bold py-24">Loading ...</h3>
      :
      <div className="w-full flex flex-col">
        {/* Bdisplay banner */}
        <div className="w-full flex justify-center relative h-72 md:h-80 lg:h-96 overflow-hidden">
          <img alt="banner" className=" object-scale-down md:object-cover lg:object-cover bg-center w-full md:w-full lg:w-full h-full" src={page?.url_banner} />
        </div>

        {/* Services */}
        <div>
          <h1 className="text-center mt-4 md:mt-12 lg:mt-12 mb-12 font-bold text-3xl">{page.title}</h1>

          <Box display={'flex'} justifyContent="center" alignItems="center" >
          {links?.map((l) => (
                <Box key={l.id} flexDirection={'column'} display="flex" justifyContent="center" alignItems="center">
                  {/* <NavLink to={l.value} target="_blank"> */}
                    <IconLink icon={getLogoUrl(l.title)} value={l.value} />
                  {/* </NavLink> */}
                </Box>
              ))}
          </Box>
          <Box mt={2} mb={12} display={'flex'} justifyContent={'center'} sx={{width:'100%'}} >
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="tabs"

            >
              <Tab
                value="one"
                label="Nos Services" 
                sx={{fontSize:'.9rem'}}
                icon={<Category/>} iconPosition="start"
              />
              <Tab value="two" icon={<Group/>} iconPosition="start" label="Notre Equipe" sx={{fontSize:'.9rem'}} />
            </Tabs>
          </Box>

          {/* Template 1 */}

          { page.card_theme === 'TYPE1' && value === 'one' && <div className="sevices-grid gap-12 md:gap-8 lg:gap-0">
            {services?.length === 0 ? (
              <div>No service</div>
            ) : (
              services?.map((service) => {
                 return (
                  <div key={service.id} className={`flex flex-col items-start w-4/5 md:w-full lg:w-4/5 h-[22rem] md:h-[22rem] lg:h-[27rem] mb-12 self-center rounded-xl relative overflow-visible shadow-2xl`}>
                    <img alt="banner" width={70} className="absolute -top-8 w-[16rem] md:w-[15rem] lg:w-[19rem] object-cover h-60 md:h-60 lg:h-80 self-center rounded-lg" src={service.url_image} />
                    <h1 className={`text-lg lg:text-xl px-6 text-black font-bold absolute top-[64%] md:top-[60%] lg:top-[70%]`}>{service.name}</h1>
                    <div className="flex gap-3 w-full items-center justify-between absolute top-[81%] md:top-[83%] lg:top-[85%] px-4">
                      {service.url ? <p
                        onClick={() => {
                          if (canSaveContactsOnConsult === true) {
                            setClickedButton('CONSULT_URL');
                            setServiceToConsult(service.id);
                            setCurrentServiceUrl(service.url);
                            setCurrentServiceUrlFile(service.url_file);
                          } else {
                            handleSendStats(service.id)
                            window.open(service.url, "_blank");
                          }
                        }}
                        className="w-full px-3 cursor-pointer flex gap-2 items-center justify-center py-2 rounded-full text-center border"
                        style={{ color: '#000', borderColor:'#000',backgroundColor:'#FFF', fontWeight:"bold"}}
                      >
                        <span className="extraSmall">
                          <InsertLinkOutlinedIcon/>
                        </span>
                        <p>Consulter</p>

                      </p>
                      : null }
                      {service.url_file ? <button
                        style={{ color: '#FFFFFF', borderColor:'#FFFFFF', fontWeight:"bold" }}
                        onClick={() => {
                          if (canSaveContacts) {
                            setClickedButton('DOWNLOAD_BROCHURE');
                            setServiceToConsult(service.id);
                            setCurrentServiceUrl(service.url);
                            setCurrentServiceUrlFile(service.url_file);
                          } else {
                            handleSendStats(service.id)
                            handleDownload(service.url_file);
                          }
                        }}
                        className="w-full flex gap-2 items-center justify-center px-3 py-2 rounded-full border-2 bg-[#7B3FFF]"
                      >
                        <p>Brochure</p>
                       <span className="extraSmall">
                        <FileDownloadOutlinedIcon/>
                       </span>
                      </button>
                      : null }
                      { canSaveContacts === true || canSaveContactsOnConsult === true ? <ContactModal
                        title="Tout d'abord, apprenons à vous connaître !"
                        refuseLabel="Fermer"
                        clickedButton={clickedButton}
                        onSuccess={() => handleSuccess(service.url,service.url_file)}
                        onError={(error)=> handleError(error)}
                        open={serviceToConsult === service.id}
                        onClose={()=> setServiceToConsult(null)}
                        service_id={service.id}
                      /> : null}

                    </div>
                  </div>
                  
                )
                
              })
              
            )}
          </div>}

          {/* Template 2 */}
          
           { page.card_theme === 'TYPE2' && value === 'one' && <div className="sevices-grid gap-12 md:gap-8 lg:gap-0">
            {services?.length === 0 ? (
              <div>No service</div>
            ) : (
              services?.map((service) => {
                 return (
                  <div key={service.id} className={`flex flex-col items-start w-4/5 border md:w-full lg:w-4/5 h-96 md:h-72 lg:h-[27rem] mb-12 self-center rounded-xl relative overflow-hidden`}>
                    <img alt="banner" className=" object-cover bg-center w-full h-full " src={service.url_image} />
                    <span className=" w-full h-96 md:h-72 lg:h-[27rem] bg-gradient-to-t from-black via-zinc-900 to-transparent absolute top-0 opacity-45 "></span>
                    <h1 className={`text-lg lg:text-2xl px-6 text-white font-bold absolute md:top-[55%] top-[63%] lg:top-[60%]`}>{service.name}</h1>
                    <div className="flex gap-3 w-full items-center justify-between absolute top-[83%] md:top-[80%] lg:top-[85%] px-4">
                      { service.url ? <p
                        onClick={() => {
                          if (canSaveContactsOnConsult === true) {
                            setClickedButton('CONSULT_URL');
                            setServiceToConsult(service.id);
                            setCurrentServiceUrl(service.url);
                            setCurrentServiceUrlFile(service.url_file);
                          } else if (canSaveContactsOnConsult === false) {
                            handleSendStats(service.id)
                            window.open(service.url, "_blank");
                          }
                        }}
                        style={{ color: '#000', borderColor:'#FFF',backgroundColor:'#FFF', fontWeight:"bold"}}
                        className="w-full flex gap-2 cursor-pointer  justify-center items-center px-3 py-2 rounded-full text-center"
                      >
                      <InsertLinkOutlinedIcon/>
                        <p>Consulter</p>
                      </p>
                      : null }
                      {service.url_file ? <button
                        style={{ color: '#FFFFFF', borderColor:'#FFFFFF', fontWeight:"bold" }}
                        onClick={() => {
                          if (canSaveContacts) {
                            setClickedButton('DOWNLOAD_BROCHURE');
                            setServiceToConsult(service.id);
                            setCurrentServiceUrl(service.url);
                            setCurrentServiceUrlFile(service.url_file);
                          } else {
                            handleSendStats(service.id)
                            handleDownload(service.url_file);
                          }
                        }}
                        className="w-full px-3 py-2 flex gap-2 justify-center items-center rounded-full border-2"
                      >
                      <FileDownloadOutlinedIcon/>
                        <p>Brochure</p>
                      </button>
                      : null }

                      { canSaveContacts === true || canSaveContactsOnConsult === true ? <ContactModal
                        title="Tout d'abord, apprenons à vous connaître !"
                        refuseLabel="Fermer"
                        clickedButton={clickedButton}
                        onSuccess={() => handleSuccess(service.url,service.url_file)}
                        onError={()=> handleError()}
                        open={serviceToConsult === service.id}
                        onClose={()=> setServiceToConsult(null)}
                        service_id={service.id}
                      /> : null}

                      
                    </div>

                  </div>
                )
              })
            )}
            </div>}

            {value === 'two' && 
              <Box display={'flex'} px={5} mt={4} sx={{width:'100%'}} gap={3} flexWrap={'wrap'} alignItems={'start'} justifyContent={{
        xs: 'center',
        sm: 'center',
        md: 'start',
        lg: 'start',
      }}>
        {users.length !== 0 && users.map((user) => (
          <div key={user.id} className="flex items-center justify-center gap-2">
             <div
                key={user.id}
                style={{
                  color: "#353535",
                  textDecoration: "none",
                  padding: 12,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
                className=" drop-shadow-lg shadow-gray-400 shadow-lg rounded-xl"
              >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      src={user?.url_profile_picture ?? DefaultProfilePic}
                      alt="profile_picture"
                      style={{
                        backgroundSize: "cover",
                        width: 250,
                        height: 250,
                        borderRadius: 10,
                        marginTop:'0'
                      }}
                    />
                  </div>
                    <div className="flex flex-col my-4 items-center">
                      <h3 className="font-bold text-lg pt-3">{user.first_name + " " + user.last_name}</h3>
                      <h5 className="font-medium pt-2">{user.function}</h5>
                    </div>                  
                <div
                  style={{
                    marginTop: 6,
                    width:'100%',
                    display: "flex",
                    alignItems:'center',
                    justifyContent: "end",
                    gap:'.5rem'
                  }}
                >
                  <NavLink to={user.url_profile} target="_blank">
                    <Button variant="secondary" startIcon={<VisibilityOutlined/>} >Voir Profile</Button>
                  </NavLink>
                  <NavLink to={user.url_vcf_file}>
                    <Button variant="primary" startIcon={<ContactsOutlined/>} >Ajouter Contact</Button>
                  </NavLink>
                </div>
              </div>
          </div>
        ))}
              </Box>
            }

        </div>

        <div className=" text-white font-bold self-center w-auto text-base my-6 mx-2 ">
            {
            page.hot_line_number && <a href={page.vcf_file} style={{'backgroundColor':'#7B3FFF'}} className="flex gap-1.5 items-center p-2 rounded-3xl">
              <DownloadSharp/>
              <p className="text-xs font-normal md:text-base lg:text-base">Enregistrer notre contact</p>
            </a>
            }
        </div>
      </div>

      }
      <a href="https://bdigitall.co" target="_blank" rel="noreferrer" className="my-4">
        <img src={bdigitall} alt="bdigitall-logo" width={130} height={130} />
      </a>
    </div>
  );
}

export default Bdisplay;
