import React from "react";
import { useState, useEffect, useRef } from "react";
import { Box, TextField, Button, Typography, Autocomplete, CircularProgress, Menu, MenuItem, Modal, TextareaAutosize, IconButton, Checkbox } from "@mui/material";
import Api from "../../api/Api";
import { ToastContainer, toast } from "react-toastify";
import JSZip from "jszip";
import axios from 'axios';
import { Urls } from "../../api/Urls";
import * as Yup from "yup";
import MzErrorText from "../../components/ui/MzErrorText";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useAuthContext } from "../../hooks/useAuthContext";
import { NavLink, useNavigate } from "react-router-dom";
import API from "../../api/Api_1_3";
import { Add, ArrowBackIos, ArrowForwardIos, CalendarMonth, Call, Check, Close, CoPresent, ContactsOutlined, Download, Edit, Email, FilterList, InfoOutlined, LinkedIn, List, MoreHoriz, Upload } from "@mui/icons-material";
import { ModalStyle } from "../../theme/ModalStyles";
import { colors } from "../../theme/Colors";
import { saveAs } from "file-saver";
import DesignServicesIcon from '@mui/icons-material/DesignServices';

function Tickets() {
  const { user } = useAuthContext();
  const access_token = user.access_token;

  const formRef = useRef();
  const formRef2 = useRef();

  //navigate to create enterprise
  const navigate = useNavigate();


  const pageNumbers = [10, 25, 50, 100];
  const [rowsAPI, setRowsAPI] = useState([]);
  const [total, setTotal] = useState(0);
  const [perPage, setPerPage] = useState(pageNumbers[1]);
  const [bdisplays,setBdisplays] = useState([])
  const [services,setServices] = useState([])
  const [selectedPage,setSelectedPage] = useState("")

  const [initialValues, setInitialValues] = useState({
    id: "",
  });

  //validation schema
  const validationSchema = Yup.object({
    id: Yup.string(),
  });

  const [initialValues2, setInitialValues2] = useState({
    id: "",
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [selectedContactType, setSelectedContactType] = useState("");
  const [selectedContacts, setSelectedContacts] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openReject, setOpenReject] = useState(false);
  const [contactId, setContactId] = useState("");

  const handleOpenReject = () => {
    setContactId(contactId);
    handleCloseMenu();
    setOpenReject(true);
  };

  const handleCloseReject = () => {
    setOpenReject(false);
    setContactId('');
  };
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);


  const getContacts = (page) => {
    Api.get(`/bdisplay-contacts?page=${page}`, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
        authorization: `Bearer ${access_token}`,
      },
    })
    .then((response) => {
      const contacts = response.data.data ?? [];
      // Reverse both contacts and their comments
      const reversedContacts = contacts.reverse().map(contact => ({
        ...contact,
        comments: contact.comments.reverse(),
      }));
      setRowsAPI(reversedContacts);
      setTotalPages(response.data.meta.last_page);
      setCurrentPage(response.data.meta.current_page);
    })
    .catch((error) => {
      console.log('Error fetching contacts:', error);
    });
  };

  const getPages = ()=> {
    Api.get(Urls.GET_BDISPLAYS + `?perPage=${perPage}`, {
      headers: {
        "Content-type": "application/json",
        accept: "application/json",
        authorization: `Bearer ${access_token}`,
      },
      params: { perPage: perPage },
    })
    .then((response) => {
      setBdisplays(response.data.pages)
      setIsLoading(false);
      })
      .catch((error) => {
        toast.error(error.response?.data?.message);
        if (error.response?.status === 401) {
          localStorage.removeItem("user");
          navigate("/login", { replace: true });
          navigate(0, { replace: true });
        }
      });
  }
  //on page load
  useEffect(() => {
    setIsLoading(true);
    getTypes();
    getPages()
  }, []);

  useEffect(()=>{
    getContacts(currentPage);
  },[currentPage])

  //on search
  const onSubmitSearchBdisplay = async (values,token) => {
    // let realValues = nonEmptyObject(values);
    const id = values.id
    console.log("selected page:",id);
    setPage(0);
    setIsLoading(true);
    Api.get(Urls.GET_BDISPLAY_CONTACTS , {
      headers: {
        "Content-type": "application/json",
        accept: "application/json",
        authorization: `Bearer ${token}`,
      },
      params: id ? { page_id: id } : null
    })
      .then((response) => {
        setIsLoading(false);
        setRowsAPI(response.data.data);
        setTotal(response.data.meta.total);
      })
      .catch((error) => {
        toast.error(error.response?.data?.message);
        if (error.response?.status === 401) {
          localStorage.removeItem("user");
          navigate("/login", { replace: true });
          navigate(0, { replace: true });
        }
      });
  };

  const handleDownload = async (values) => {
    console.log(values)
    try {
      const params = values.id !== "" ? { page_id: values.id } : null;
      const response = await Api.get('/bdisplay-export-contacts', {
        headers: {
          accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          authorization: `Bearer ${access_token}`,
        },
        params: params,
        responseType: 'blob', // Ensure Axios treats the response as a blob
      });
  
      const blob = response.data; // Use response.data to access the blob
  
      const url = window.URL.createObjectURL(new Blob([blob]));
  
      const link = document.createElement('a');
      link.href = url;
      link.target = '_blank';
      link.setAttribute('download', `Bdisplay_contacts.xlsx`);
  
      document.body.appendChild(link);
  
      setTimeout(() => {
        link.click();
        // Clean up resources
        window.URL.revokeObjectURL(url);
        document.body.removeChild(link);
      }, 100); // Delay the click to ensure the link is appended
    } catch (error) {
      toast.error('Failed to export contacts');
      console.error('Error fetching the file:', error);
    }
  };

  const [possibleActionsList,setList] = useState([
    {
      id: 9999,
      type_designation: 'Autre',
    },
  ])

  const getTypes = () => {
    API.get('/comment-types', {
      headers: {
        "Content-type": "application/json",
        accept: "application/json",
        authorization: `Bearer ${access_token}`,
      },
    })
    .then((response) => {
      const apiData = response.data.data ?? [];
      // Filter out any item with id 9999 to avoid duplication
      const filteredData = apiData.filter(item => item.id !== 9999);
      // Combine the default value with the filtered API data
      setList([
        ...filteredData,
        {
          id: 9999,
          type_designation: 'Autre',
        },
      ]);
    })
      .catch((error) => {
        console.log(error);
      });
  };

  const [editedComments, setEditedComments] = useState({});

  const handleEditComment = (id, newComment) => {
    setEditedComments({ ...editedComments, [id]: newComment });
  };

  const handleEditCommentOnSubmit = async (id, newComment) => {
    const trimmed = newComment.trim()
    handleShowEdit(null)
    try {
      await API.patch(
        "/pages/comments/" + id,
        {
          comment: trimmed,
        },
        {
          headers: {
            "Content-type": "application/json",
            accept: "application/json",
            authorization: `Bearer ${access_token}`,
          },
        }
      );
      toast.success('Operation success');
      getContacts();
    } catch (error) {
      console.error("Error editing comment:", error);
      toast.error("Failed to edit comment");
    }
  };
  
  const handleAddComment = async (id, values) => {
    const { comment_type, comment } = values;
    let payload = {
      bdisplay_contact_id: id,
      comment: comment,
    };

    if (comment_type.type_designation !== 'Autre') {
      payload.comment_type_id = comment_type.id;
    }
    try {
      await API.post(
        "/pages/comments",
        payload,
        {
          headers: {
            "Content-type": "application/json",
            accept: "application/json",
            authorization: `Bearer ${access_token}`,
          },
        }
      );
      toast.success('Operation success');
      getContacts()
    } catch (error) {
      console.error("Error adding contact:", error);
      toast.error("Failed to add comment");
    }
    handleCloseReject();
  };

  const handleShowMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  
  const [openComments,setOpenComments] = useState(false)
  const handleClose = () => setOpenComments(false)
  const handleOpen = () => setOpenComments(true)

  const [selectedContactId, setSelectedContactId] = useState(null);

  const handleOpenComments = () => {
    setSelectedContactId(contactId);
  };
  
  const showMenu = Boolean(anchorEl);

  const getIconByType = (type,color) => {
    switch (type) {
      case 'Call':
        return <Call sx={{color:color}} />;
      case 'Email':
        return <Email  sx={{color:color}}/>;
      case 'LinkedIn':
        return <LinkedIn  sx={{color:color}}/>;
      case 'RDV Présentation':
        return <CalendarMonth sx={{color:color}}/>;
      case 'Démo':
        return <CoPresent sx={{color:color}} />;
      default:
        return <DesignServicesIcon sx={{color:color}} />;
    }
  };

  const handleSelectAllChange = (event) => {
    setSelectAll(event.target.checked);
    if (event.target.checked) {
      setSelectedContacts(rowsAPI.map((contact) => contact.id));
    } else {
      setSelectedContacts([]);
    }
  };

  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };
  
  useEffect(() => {
    console.log('Selected Contact ID:', selectedContactId);
  }, [selectedContactId]);
    
  const downloadZip = async () => {
    const zip = new JSZip();
    for (const contactId of selectedContacts) {
      const contact = rowsAPI.find(contact => contact.id === contactId);
      if (contact && contact.url_vcf_file) {
        try {
          const response = await axios.get(contact.url_vcf_file, {
            responseType: 'blob',
          });
          const blob = response.data;
          zip.file(`${contact.first_name}_${contact.last_name}.vcf`, blob);
        } catch (error) {
          toast.error(`Failed to fetch VCF for contact ${contact.first_name} ${contact.last_name}`);
          console.error(`Error fetching the VCF file for contact ${contactId}:`, error);
        }
      }
    }
    zip.generateAsync({ type: "blob" }).then(content => {
      saveAs(content, "contacts.zip");
    });
  };

  const [editCommentId, setEditCommentId] = useState(null);

  const handleShowEdit = (commentId) =>{
    setEditCommentId(commentId);
  }

  const textAreaRef = useRef(null);

  useEffect(() => {
    if (textAreaRef.current) {
      textAreaRef.current.focus();
    }
  }, [editCommentId]);
 

  return (
    <Box>
      {/* Header Section */}
      {/* Form Section */}

      <Box className="filter" display="flex" sx={{'flexDirection':{xs:'column',sm:'column',md:'row',lg:'row'},alignItems:{xs:'start',sm:'start',md:'center',lg:'start'},justifyContent:{xs:'start',sm:'start',md:'space-between',lg:'space-between'}}}  >

        <Formik
          innerRef={formRef}
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            onSubmitSearchBdisplay(values, access_token);
          }}
        >
          {({ values, setFieldValue }) => (
            <Form>
              <Box display="flex" flexDirection="column">
                <Box>
                  <Typography noWrap mr marginTop="0.45rem" mb>
                    Filtre
                  </Typography>
                </Box>
                <ToastContainer style={{ top: "5rem" }} position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="dark" />

                {/*Search fields */}

                <Box display="flex" sx={{ flexDirection: { md: "row", xs: "column" }, alignItems:'center', justifyContent:'between' }} marginBottom="1rem">
                  
                  <Box display="flex" sx={{'flexDirection':{xs:'column',sm:'column',md:'row',lg:'row',xl:'lg'}}}>
                    <Box flex={1} alignItems="center" marginRight="1rem" mb>
                    <Field
                      name="id"
                      component={Autocomplete}
                      options={bdisplays.length > 0 ? bdisplays : []}
                      isOptionEqualToValue={(option, value) => option === value}
                      getOptionLabel={(s) => s.title ?? ""}
                      onChange={(event, value) => {
                        setFieldValue("id", value ? value.id : null);
                        setSelectedPage(value ? value.title : null)
                      }}
                      renderInput={(params) => <TextField {...params} name="autocomplete" label="Bdisplays" sx={{width:350}} />}
                      size="small"
                    />{" "}
                    <ErrorMessage name="Bdisplays" component={MzErrorText} />
                    </Box>
                    <Box marginRight="1rem" display={'flex'} alignItems={'center'} gap={'1rem'} justifyContent={'center'} mb>
                      <Button type="submit" variant="primary">
                        <FilterList/>
                      </Button>
                      <Button startIcon={<Upload/>} onClick={()=>handleDownload(values)} variant="primary">
                        <p className='mr-1'>Exporter </p> 
                        <p>{ selectedPage ? "La page selectionée" : "Tous"}</p>
                      </Button>
                    </Box>
                  </Box>

                </Box>
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
      {/* End form section */}

      <div className="table-container">
        <table className="styled-table overflow-x-auto">
          <thead>
            <tr>
            <th scope="col" className="px-6 py-3">
                <Checkbox
                  checked={selectAll}
                  onChange={handleSelectAllChange}
                  indeterminate={
                    selectedContacts.length > 0 &&
                    selectedContacts.length < rowsAPI.length
                  }
                />
              </th>
              <th scope="col" className="px-6 py-3">Nom</th>
              <th scope="col" className="px-6 py-3">Email</th>
              <th scope="col" className="px-6 py-3">Téléphone</th>
              <th scope="col" className="px-6 py-3">Entreprise</th>
              <th scope="col" className="px-6 py-3">Fonction</th>
              <th scope="col" className="px-6 py-3">Bdisplay</th>
              <th scope="col" className="px-6 py-3">Service</th>
              <th scope="col" className="px-6 py-3">Action</th>
            </tr>
          </thead>
          <tbody>
            {rowsAPI.length === 0 && <div className="text-center my-4"><CircularProgress /></div>}
            {rowsAPI.length > 0 && rowsAPI.map((contact) => (
              <tr key={contact.id}>
                <td>
                  <Checkbox
                    checked={selectedContacts.includes(contact.id)}
                    onChange={(event) => {
                      if (event.target.checked) {
                        setSelectedContacts([...selectedContacts, contact.id]);
                      } else {
                        setSelectedContacts(selectedContacts.filter(id => id !== contact.id));
                      }
                    }}
                  />
                </td>
                <td>{contact?.first_name + " " + contact?.last_name ?? '---'}</td>
                <td>{contact?.email ?? '---'}</td>
                <td>{contact?.phone ?? '---'}</td>
                <td>{contact.company ?? '---'} </td>
                <td>{contact?.function ?? '---'} </td>
                <td>{contact?.bdisplay_name ?? '---'} </td>
                <td>{contact?.service_name ?? '---'} </td>
                <td>
                  <IconButton onClick={(event) => {
                    handleShowMenu(event);
                    setContactId(contact.id);
                  }}>
                    <MoreHoriz />
                  </IconButton>
                  <Menu
                    anchorEl={anchorEl}
                    open={showMenu}
                    onClose={handleCloseMenu}
                    PaperProps={{ elevation: 2 }}
                  >
                    <MenuItem onClick={handleCloseMenu} sx={{display: 'flex', gap:1}}>
                      <ContactsOutlined sx={{width:25}}/>
                      <NavLink target="_blank" to={contact?.url_vcf_file}>Ajouter Contact</NavLink>
                    </MenuItem>
                    <MenuItem onClick={() => {
                      handleOpenReject(contact.id)
                      }}
                      sx={{display: 'flex', gap:1}}
                      >
                        <Add/>
                        Ajouter Commentaire</MenuItem>
                    <MenuItem onClick={() => {
                      handleOpenComments(contact.id)
                      handleOpen()
                      }}
                    sx={{display: 'flex', gap:1}}
                    >
                      <List/>
                      Voir List des Commentaires</MenuItem>
                  </Menu> 
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="pagination-controls self-end">
        <Button
          disabled={currentPage === 1}
          onClick={() => handlePageChange(currentPage - 1)}
        >
          <ArrowBackIos/>
        </Button>
        <span>Page {currentPage} of {totalPages}</span>
        <Button
          disabled={currentPage === totalPages}
          onClick={() => handlePageChange(currentPage + 1)}
        >
          <ArrowForwardIos/>
        </Button>
        </div>
      </div>
      <div style={{ marginTop: "20px" }}>
        <Button startIcon={<Download/>} variant="contained" onClick={downloadZip} disabled={selectedContacts.length === 0}>
          Télécharger les contacts séléctionnés (ZIP)
        </Button>
      </div>
      {openReject && (
        <Formik
          initialValues={{ comment: '', comment_type: null }}
          onSubmit={(values) => handleAddComment(contactId, values)}
          validationSchema={validationSchema}
        >
          {({ values, handleChange, setFieldValue, handleSubmit, errors, touched }) => (
            <Modal
              open={openReject}
              onClose={handleCloseReject}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={ModalStyle}>
                <h2 className="text-2xl mb-4">Ajouter un Commentaire</h2>
                <Form onSubmit={handleSubmit}>
                  <Box className="field" sx={{ marginBottom: "1rem" }}>
                  <Field
                      name="comment_type_id"
                      component={Autocomplete}
                      options={possibleActionsList}
                      isOptionEqualToValue={(option, value) => option.id === value?.id}
                      getOptionLabel={(option) => option.type_designation}
                      onChange={(event, value) => {
                        setFieldValue("comment_type", value);
                      }}
                      renderInput={(params) => <TextField {...params} name="autocomplete" label="Action" />}
                      size="small"
                      error={touched.comment_type}
                    />
                    <ErrorMessage name="comment_type" component={MzErrorText} />
                  </Box>
                  <Field
                    as={TextField}
                    label="Commentaire"
                    name="comment"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    multiline
                    rows={4}
                    error={touched.comment && Boolean(errors.comment)}
                    helperText={<ErrorMessage name="comment" component="div" className="error" />}
                  />
                  <div className="flex justify-end">
                    <Button variant="secondary" onClick={handleCloseReject} sx={{ mr: 2 }}>Annuler</Button>
                    <Button type="submit" variant="primary">Ajouter</Button>
                  </div>
                </Form>
              </Box>
            </Modal>
          )}
        </Formik>
      )}

      {
        openComments && (
          <Modal
          open={openComments}
          onClose={handleClose}
          >
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={openComments}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              <MenuItem>
              <Box width={'100%'} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                  <Typography variant="h6" color="initial">Commentaires</Typography>
                  <Box onClick={handleClose}>
                    <Close/>
                  </Box>
                </Box>
              </MenuItem>
              {rowsAPI && rowsAPI.map((contact, index) => (
              <div key={index}>
                {selectedContactId === contact.id && contact.comments && contact.comments.map((comment, commentIndex) => (
                  <MenuItem key={comment.id} sx={{ width: '100%', display: 'flex', flexDirection: 'row', padding: '1rem', alignItems: 'center', borderBottom: '.5px solid #232323', gap: '.5rem' }}>
                    <Box>
                      {getIconByType(comment.comment_type,colors.bd_Purple)}
                    </Box>
                    <Box width={500} display={'flex'} flexDirection={'column'} alignItems={'start'}>
                      {editCommentId === comment.id ? 
                      <TextareaAutosize
                      rowsMin={3}
                      maxRows={5}
                      value={editedComments[comment.id] || comment.comment}
                      onChange={(e) => handleEditComment(comment.id, e.target.value)}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter' && !e.shiftKey) {
                          e.preventDefault(); 
                          handleEditCommentOnSubmit(comment.id, editedComments[comment.id]);
                        }
                      }}
                      autoFocus
                      ref={textAreaRef}
                      style={{ width: '100%', fontSize: '1rem', padding:3, fontWeight: 450, backgroundColor:'transparent' }}
                    />
                      : <Typography variant="body1">{editedComments[comment.id] || comment.comment}</Typography>
                    }
                    <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} sx={{width:'100%'}}>
                      <Typography variant="body1" color="initial" sx={{ whiteSpace: 'break-spaces', fontSize: '.9rem', opacity: 0.7 }}>{comment.user} - {comment.is_updated ? comment.updated_at : comment.created_at} {comment.is_updated && '(Modifié)'}</Typography>
                      <Box display={'flex'} gap={1}>
                        {
                        editCommentId === comment.id &&
                        <>
                          <Check sx={{color: colors.mjGreenText}} onClick={() => handleEditCommentOnSubmit(comment.id, editedComments[comment.id])}/>
                          <Close sx={{color: colors.mj_red}} onClick={()=> handleShowEdit(null)}/>
                        </>
                        }
                      </Box>
                    </Box>
                    </Box>
                    <Box>
                      <Button variant={ editCommentId === comment.id ? "disabled" : "secondary"}  onClick={() => handleShowEdit(comment.id)}><Edit/></Button>
                    </Box>
                  </MenuItem>
          ))}
  </div>
))}


</Menu>

          </Modal>
        )
      }
    </Box>
  );
}

export default Tickets;
